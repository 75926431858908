import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import {
  getPriceListUpdateDataUpdated,
  getPricingPriceListData,
  updatePriceListUpdateData,
} from '../api';
import {
  PriceListTableData,
  PricingPriceListData,
  Unit,
} from '../api/mockData/priceListData';
import {
  GetPriceListDataParams,
  GetPriceListUpdateDataParamsUpdated,
  UpdatePriceListUpdateDataParams,
} from '../api/types';
import {
  convertPricingDataToTable,
  logError,
  sortByString,
} from '../utils/utils';
import { BuildingData, StoreModel } from './types';
import { PriceListUpdateData } from '../api/mockData/priceUpdateData';
import {
  Flat,
  PercentageChange,
  PriceUpdateData,
} from '../api/mockData/priceUpdateDataUpdated';

export interface PriceListStore {
  showPricePerSM: boolean;
  showAnalyticsUpdated: Computed<PriceListStore, boolean, StoreModel>;
  setShowPricePerSM: Action<PriceListStore, boolean>;
  layoutFilter: string[];
  setLayoutFilter: Action<PriceListStore, string[]>;
  selectedId?: number;
  setSelectedId: Action<PriceListStore, number>;
  setSelectedUnitId: Action<PriceListStore, string>;
  selectedPricingId?: number;
  setSelectedPricingId: Action<PriceListStore, number>;
  selectedPricingUpdateId?: number;
  setSelectedPricingUpdateId: Action<PriceListStore, number>;
  selectedPricingData: Computed<PriceListStore, Unit | undefined, StoreModel>;
  selectedPricingUpdateData: Computed<
    PriceListStore,
    Flat | undefined,
    StoreModel
  >;
  data?: PriceListTableData[];
  buildingsData: Computed<PriceListStore, BuildingData[], StoreModel>;
  buildingsDataUpdated: Computed<PriceListStore, BuildingData[], StoreModel>;
  setData: Action<PriceListStore, PriceListTableData[]>;
  fetchData: Thunk<PriceListStore, GetPriceListDataParams, unknown, StoreModel>;
  fetchUpdateDataUpdated: Thunk<
    PriceListStore,
    GetPriceListUpdateDataParamsUpdated,
    unknown,
    StoreModel
  >;
  updateAttributes: Thunk<
    PriceListStore,
    UpdatePriceListUpdateDataParams,
    unknown,
    StoreModel
  >;
  attributesBeingUpdated?: boolean;
  setAttributesBeingUpdated: Action<PriceListStore, boolean>;
  pageSize?: number;
  setPageSize: Action<PriceListStore, number>;
  setUpdateData: Action<PriceListStore, PriceListUpdateData[]>;
  updateData?: PriceListUpdateData[];
  updateDataUpdated?: PriceUpdateData;
  setUpdateDataUpdated: Action<PriceListStore, PriceUpdateData>;
  pricingData?: PricingPriceListData;
  setPricingData: Action<PriceListStore, PricingPriceListData>;
}

export const priceListStore: PriceListStore = {
  showPricePerSM: false,
  showAnalyticsUpdated: computed([(state) => state.pricingData], (data) => {
    const analyticsData = data?.units?.map((d) => ({
      unique_sessions: d.google_analytics?.average_duration_seconds,
      average_duration_seconds: d.google_analytics?.color_temperature,
      google_analytics_normalized: d.google_analytics?.unique_sessions,
    }));

    const allValuesAreZero = analyticsData?.every(
      (d) =>
        !d.unique_sessions &&
        !d.average_duration_seconds &&
        !d.google_analytics_normalized,
    );
    return !allValuesAreZero;
  }),
  setShowPricePerSM: action((state, value) => {
    state.showPricePerSM = value;
  }),
  layoutFilter: [],
  setLayoutFilter: action((state, value) => {
    state.layoutFilter = value;
  }),
  setSelectedId: action((state, newId) => {
    state.selectedId = newId;
  }),
  setSelectedUnitId: action((state, newId) => {
    const { pricingData } = state;
    const selectedFlat = pricingData?.units?.find(
      (value) => value.name === newId,
    );
    state.selectedPricingId = selectedFlat?.id;
  }),
  setSelectedPricingId: action((state, newId) => {
    state.selectedPricingId = newId;
  }),
  setSelectedPricingUpdateId: action((state, newId) => {
    state.selectedPricingUpdateId = newId;
  }),
  setAttributesBeingUpdated: action((state, value) => {
    state.attributesBeingUpdated = value;
  }),
  selectedPricingData: computed(
    [(state) => state.selectedPricingId, (state) => state.pricingData],
    (selectedPricingId, pricingData) =>
      pricingData?.units?.find((value) => value.id === selectedPricingId),
  ),
  selectedPricingUpdateData: computed(
    [
      (state) => state.selectedPricingUpdateId,
      (state) => state.updateDataUpdated,
    ],
    (selectedPricingUpdateData, updateDataUpdated) =>
      updateDataUpdated?.units?.find(
        (value) => value.id === selectedPricingUpdateData,
      ),
  ),
  buildingsDataUpdated: computed(
    (state) =>
      convertPricingDataToTable(state.pricingData)?.reduce<BuildingData[]>(
        (prev, curr) => {
          const result = [...prev];
          const buildingIndex = result.findIndex(
            (val) => val.id === curr.building_id,
          );
          if (buildingIndex > -1) {
            const floorIndex = result[buildingIndex].floors.findIndex(
              (floor) => floor.floorNumber === curr.floor,
            );
            if (floorIndex > -1) {
              result[buildingIndex].floors[floorIndex].flats.push(curr);
              result[buildingIndex].floors[floorIndex].flats.sort((a, b) =>
                sortByString(a.internal_id, b.internal_id),
              );
            } else {
              result[buildingIndex].floors.push({
                floorNumber: curr.floor,
                flats: [curr],
              });
              result[buildingIndex].floors.sort(
                (a, b) => b.floorNumber - a.floorNumber,
              );
            }
          } else {
            result.push({
              name: curr.building_internal_id,
              id: curr.building_id,
              floors: [{ floorNumber: curr.floor, flats: [curr] }],
            });
          }
          return result;
        },
        [],
      ) ?? [],
  ),
  buildingsData: computed(
    (state) =>
      state.data?.reduce<BuildingData[]>((prev, curr) => {
        const result = [...prev];
        const buildingIndex = result.findIndex(
          (val) => val.id === curr.building_id,
        );
        if (buildingIndex > -1) {
          const floorIndex = result[buildingIndex].floors.findIndex(
            (floor) => floor.floorNumber === curr.floor,
          );
          if (floorIndex > -1) {
            result[buildingIndex].floors[floorIndex].flats.push(curr);
            result[buildingIndex].floors[floorIndex].flats.sort((a, b) =>
              sortByString(a.internal_id, b.internal_id),
            );
          } else {
            result[buildingIndex].floors.push({
              floorNumber: curr.floor,
              flats: [curr],
            });
            result[buildingIndex].floors.sort(
              (a, b) => b.floorNumber - a.floorNumber,
            );
          }
        } else {
          result.push({
            name: curr.building_internal_id,
            id: curr.building_id,
            floors: [{ floorNumber: curr.floor, flats: [curr] }],
          });
        }
        return result;
      }, []) ?? [],
  ),
  setData: action((state, data) => {
    state.data = data;
  }),
  setPricingData: action((state, data) => {
    state.pricingData = data;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const pricingData = await getPricingPriceListData({
        projectId: payload.projectId,
      });
      const updatedData = pricingData.data;
      const allUnits: Unit[] = updatedData.flats ?? updatedData.houses ?? [];
      const updatedPricingData = {
        units: allUnits,
      };

      actions.setPricingData(updatedPricingData);
      if (allUnits && allUnits?.length > 0) {
        actions.setSelectedPricingId(allUnits[0].id);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  fetchUpdateDataUpdated: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const { data } = await getPriceListUpdateDataUpdated(payload);
      const formattedData = {
        ...data,
        units: data?.flats ?? data?.houses ?? [],
      };
      actions.setUpdateDataUpdated(formattedData);
      if (data) {
        actions.setSelectedPricingUpdateId(formattedData?.units[0]?.id);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  updateAttributes: thunk(async (actions, payload, helpers) => {
    const {
      priceList: { setAttributesBeingUpdated },
    } = helpers.getStoreActions();
    try {
      setAttributesBeingUpdated(true);
      const { data } = await updatePriceListUpdateData(payload);
      const formattedData = {
        ...data,
        units: data?.flats ?? data?.houses ?? [],
      };
      actions.setUpdateDataUpdated(formattedData);
      if (data) {
        actions.setSelectedPricingUpdateId(formattedData?.units[0]?.id);
      }
    } catch (error) {
      logError(error);
    } finally {
      setAttributesBeingUpdated(false);
    }
  }),
  setUpdateData: action((state, data) => {
    state.updateData = data;
  }),
  setUpdateDataUpdated: action((state, data) => {
    state.updateDataUpdated = data;
  }),
  setPageSize: action((state, data) => {
    state.pageSize = data;
  }),
};
