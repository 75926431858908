import { Popover } from 'antd';
import { FC } from 'react';
import { UpdateDetails } from '../../api/mockData/priceUpdateDataUpdated';

type Props = {
  updates: UpdateDetails[];
  title: string;
  tooltip: string;
};

export const PanelHeader: FC<Props> = ({ updates, title, tooltip }) => (
  <Popover
    content={
      <div>
        <p className='font-bold'>{title}</p>
        <span>{tooltip}</span>
      </div>
    }
  >
    <span className='text-[#828282]'>{`${title} (${updates.length})`}</span>
  </Popover>
);
