/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Spin } from 'antd';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PriceTrendEnum } from '../../../pages/PriceUpdate/PriceUpdatePage';
import { formatPercent } from '../../../utils';
import {
  PercentageChange,
  UpdateDetails,
} from '../../../api/mockData/priceUpdateDataUpdated';
import { useRevenueChange } from './useRevenueChange';
import { useStoreState } from '../../../hooks';

type Props = {
  priceTrend: PriceTrendEnum;
  revenue_change_percentage: number;
  percentageChangeArr: PercentageChange[];
  setPercentageChangeArr: Dispatch<SetStateAction<PercentageChange[]>>;
  partialAttribute: Partial<UpdateDetails>;
  isDisabled: boolean;
  avg_update_percentage: number;
  handleDisableAttribute: (attribute: Partial<UpdateDetails>) => void;
  user_percentage_change: number | null;
};

export const UpdateTag: FC<Props> = ({
  priceTrend,
  revenue_change_percentage,
  percentageChangeArr,
  setPercentageChangeArr,
  partialAttribute,
  isDisabled,
  avg_update_percentage,
  handleDisableAttribute,
  user_percentage_change,
}) => {
  const { attribute_by_layout, attribute_flag, attribute_name } =
    partialAttribute;
  const [isHovered, setIsHovered] = useState(false);
  const [isEditingValue, setIsEditingValue] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(
    (revenue_change_percentage * 100).toFixed(1),
  );
  const [initialInputValue, setInitialInputValue] = useState(inputValue);

  const { attributesBeingUpdated } = useStoreState((state) => state.priceList);

  const {
    updatedChange,
    setUpdatedChange,
    canIncrease,
    canDecrease,
    increaseRevenueChange,
    decreaseRevenueChange,
    isValidInputValue,
  } = useRevenueChange({
    priceTrend,
    revenue_change_percentage,
    avg_update_percentage,
    percentageChangeArr,
    setPercentageChangeArr,
    handleDisableAttribute,
    partialAttribute,
    attributeDetails: {
      attribute_by_layout,
      attribute_flag: String(attribute_flag),
      attribute_name: String(attribute_name),
    },
  });

  useEffect(() => {
    setInputValue((updatedChange * 100).toFixed(1));
  }, [updatedChange]);

  const saveValue = (): void => {
    const newValue = isValidInputValue(Number(inputValue) / 100);
    setInputValue((newValue * 100).toFixed(1));
    if (!Number.isNaN(newValue)) {
      setPercentageChangeArr((prev) =>
        prev.map((item) =>
          item.attribute_name === attribute_name
            ? { ...item, revenue_change_percentage: newValue }
            : item,
        ),
      );
      setUpdatedChange(newValue);
    }
  };

  const handleInputBlur = (): void => {
    if (inputValue !== initialInputValue) {
      saveValue();
    }
    setIsEditingValue(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue !== initialInputValue) {
        saveValue();
      }
      setIsEditingValue(false);
    }
    if (e.key === 'Escape') {
      setUpdatedChange(revenue_change_percentage);
      setIsEditingValue(false);
    }
  };

  const startEditing = (): void => {
    if (!isDisabled) {
      setIsEditingValue(true);
      setInitialInputValue(inputValue); // Store the initial value when editing starts
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  };

  const validateNumberInput = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    const inputElement = event.target as HTMLInputElement;
    let { value } = inputElement;

    // Regular expression to allow:
    // - Optional '-' at the start
    // - Digits before the decimal point
    // - At most one decimal point, and at most one digit after it
    const regex = /^-?\d*(\.\d?)?$/;

    if (!regex.test(value)) {
      // Clean the input to match the required format:
      value = value
        .replace(/[^0-9.-]/g, '') // Remove invalid characters
        .replace(/(?!^)-/g, '') // Keep '-' only at the start
        .replace(/(\..*?)\./g, '$1') // Remove extra decimal points
        .replace(/^(-?\d*\.\d?).*$/, '$1'); // Keep only one digit after the decimal
    }

    inputElement.value = value; // Update the input element
  };

  const updateValue =
    updatedChange > 0
      ? `+${formatPercent(updatedChange)}`
      : `${formatPercent(updatedChange)}`;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className='grid grid-cols-[60px_2.5rem] gap-1 items-center ml-[1rem]'
    >
      {attributesBeingUpdated ? (
        <div className='w-full flex justify-center'>
          <Spin />
        </div>
      ) : (
        <div className='text-right'>
          {isEditingValue ? (
            <input
              onInput={validateNumberInput}
              ref={inputRef}
              type='text'
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              onClick={(e) => e.stopPropagation()}
              className={clsx(
                priceTrend === PriceTrendEnum.DECREASE
                  ? 'focus:bg-[#DEDEDE]'
                  : 'focus:bg-[#CCD9EC]',
                'rounded-[14.5px] py-1 h-fit w-full text-center focus:outline-none focus:ring-0',
              )}
            />
          ) : (
            <span
              onClick={(e) => {
                e.stopPropagation();
                startEditing();
              }}
              className={clsx(
                formatPercent(user_percentage_change ?? 0) !== '0.0%'
                  ? updatedChange > 0
                    ? 'border-[#313B5E] border-[1px]'
                    : 'border-[#828282] border-[1px]'
                  : '',
                'rounded-[14.5px] px-3 py-1 h-fit w-fit cursor-text',
                updatedChange > 0 ? 'bg-[#CCD9EC]' : 'bg-[#DEDEDE]',
              )}
            >
              {updateValue}
            </span>
          )}
        </div>
      )}
      <div
        className={clsx(
          !isHovered ? 'opacity-0' : 'opacity-1',
          'text-[#828282] grid grid-rows-2 h-full',
        )}
      >
        {canIncrease() && !isDisabled && !attributesBeingUpdated ? (
          <ChevronUpIcon
            onClick={(e) => {
              e.stopPropagation();
              increaseRevenueChange();
            }}
            className='w-8 stroke-2'
          />
        ) : (
          <div onClick={(e) => e.stopPropagation()} className='w-full h-full' />
        )}
        {canDecrease() && !isDisabled && !attributesBeingUpdated ? (
          <ChevronDownIcon
            onClick={(e) => {
              e.stopPropagation();
              decreaseRevenueChange();
            }}
            className='w-8 stroke-2'
          />
        ) : (
          <div onClick={(e) => e.stopPropagation()} className='w-full h-full' />
        )}
      </div>
    </div>
  );
};
