import { FC, ReactNode, useState } from 'react';
import { TFunction } from 'i18next';
import { Column, ColumnConfig, AreaConfig, Area } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { AVAILABLE, GREY_2 } from '../../../../styles/constants';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { FilterEnum } from '.';
import {
  SupplyHistoryDataResponse,
  SupplyHistoryNewData,
  SupplyHistoryOverallData,
} from '../../../../api/types';
import { useStoreState } from '../../../../hooks';
import { SupplyHistoryModal } from './SupplyHistoryModal/SupplyHistoryModal';

enum ProjectPhase {
  UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
  COMPLETED = 'COMPLETED',
  OVERALL = 'OVERALL',
}

export interface SupplyHistoryData {
  available: number,
  date: string,
  reserved: number,
  type: 'overall' | 'new',
}

interface Props {
  filter: FilterEnum;
  data: SupplyHistoryDataResponse;
  showBothLines: boolean;
}

const getChart = (
  filter: FilterEnum,
  config: AreaConfig | ColumnConfig,
): ReactNode => {
  if (filter === FilterEnum.NEW) {
    return <Column {...(config as ColumnConfig)} />;
  }
  return <Area {...(config as AreaConfig)} />;
};

const getLabelText = (
  underConstruction: boolean,
  type: ProjectPhase,
  t: TFunction<'translation', undefined, 'translation'>,
  filter: FilterEnum,
): string => {
  if (!underConstruction || filter === FilterEnum.NEW) {
    return t('charts.state.available');
  }

  return type === ProjectPhase.UNDER_CONSTRUCTION
    ? t(
        'charts.state.available.under_construction',
        'Available under construction',
      )
    : t('charts.state.available.completed', 'Available completed');
};

export const SupplyHistoryChart: FC<Props> = ({
  data,
  filter,
  showBothLines,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { displayValues } = useStoreState((state) => state.filters);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<SupplyHistoryData | null>(null);

  const overallLabel = displayValues
    ? {
        style: {
          color: '#000',
        },
        offsetX: 0.5,
        rotate: -Math.PI / 3,
      }
    : undefined;

  const newLabel = displayValues
    ? {
        style: {
          color: '#000',
        },
        position: 'top' as const,
      }
    : undefined;

  let combinedData: (SupplyHistoryOverallData | SupplyHistoryNewData)[] = [];

  // Handle data based on the filter type
  if (filter === FilterEnum.OVERALL) {
    if (showBothLines) {
      // Show both overall and under_construction data
      combinedData = [
        ...data.overall.map((item) => ({
          ...item,
          type: ProjectPhase.OVERALL,
        })),
        ...data.under_construction.map((item) => ({
          ...item,
          type: ProjectPhase.UNDER_CONSTRUCTION,
        })),
      ];
    } else {
      // Only show overall data
      combinedData = data.overall.map((item) => ({
        ...item,
        type: ProjectPhase.OVERALL,
      }));
    }
  } else {
    // If filter is not OVERALL, use the "new" data
    combinedData = data.new.map((item) => ({ ...item, type: 'New' }));
  }

  const chartColors = showBothLines ? [AVAILABLE, GREY_2] : AVAILABLE;

  const config: ColumnConfig = getColumnOptions({
    smooth: true,
    data: combinedData,
    color: filter === FilterEnum.OVERALL ? chartColors : AVAILABLE,
    xField: 'date',
    yField: filter === FilterEnum.OVERALL ? 'available' : 'new',
    seriesField: 'type',
    label: filter === FilterEnum.OVERALL ? overallLabel : newLabel,
    isStack: false,
    tooltip: {
      formatter: (datum) => {
        if (filter === FilterEnum.OVERALL) {
          const value = datum as SupplyHistoryOverallData;
          return {
            name: value.date,
            value: value.available ?? '-',
          };
        }
        const value = datum as SupplyHistoryNewData;
        return {
          name: value.date,
          value: value.new ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${getLabelText(
                showBothLines,
                item.data.type,
                t,
                filter,
              )}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'short',
            year: 'numeric',
          });
        },
      },
    },
    yAxis: {
      max:
        filter !== FilterEnum.OVERALL
          ? Math.max(...data.new.map((d) => d.new)) * 1.1
          : undefined,
      label: {
        formatter: (value) => value,
      },
    },
    legend: {
      itemName: {
        formatter: (text) => {
          if (!showBothLines || filter === FilterEnum.NEW) {
            return t('charts.state.available');
          }
          if (text === ProjectPhase.UNDER_CONSTRUCTION) {
            return t(
              'charts.state.available.under_construction',
              'Available under construction',
            );
          }
          return t('charts.state.available.completed', 'Available completed');
        },
      },
    },
    onReady: (plot) => {
      // @ts-expect-error
      plot.on('plot:mousemove', (evt) => {
        // pointer cursor
        const canvasElement = plot.chart.getCanvas().get('el');
        const elements = plot.chart.getTooltipItems({ x: evt.x, y: evt.y });
        if (elements.length) {
          canvasElement.style.cursor = 'pointer';
        } else {
          canvasElement.style.cursor = 'default';
        }
      });
      // @ts-expect-error
      plot.on('plot:click', (evt) => {
        const { x, y } = evt;
        // Get the elements at the click position
        const elements = plot.chart.getTooltipItems({ x, y });
        if (elements.length) {
          // Assuming the first item corresponds to the clicked column
          if (elements[0].data.value !== 0 && filter === FilterEnum.NEW) {
            setIsModalVisible(true);
            setModalData(elements[0].data);
          }
        }
      });
    },
  });

  return data ? (
    <>
      {getChart(filter, config)}
      <SupplyHistoryModal
        open={isModalVisible}
        setOpen={setIsModalVisible}
        modalData={modalData}
      />
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
