import {
  ChevronDoubleUpIcon,
  XMarkIcon,
  ChevronDoubleDownIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useStoreState } from '../../hooks';
import { SimplifiedProjects } from '../../store/market';
import { useMap } from '../../hooks/useMap';

type Props = {
  setIsHovered?: Dispatch<SetStateAction<string | null>>;
  mapParam: google.maps.Map;
  projects?: SimplifiedProjects[];
};

export const Selection: FC<Props> = ({
  setIsHovered,
  mapParam,
  projects = [],
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { polygons, circles, activeTags } = useStoreState(
    (state) => state.market,
  );
  const polygonNames = Object.keys(polygons);
  const circleNames = Object.keys(circles);

  const filteredProjectsByPhases = useMemo(
    () =>
      projects.filter(({ project_phase }) =>
        activeTags.includes(project_phase),
      ),
    [activeTags],
  );

  const { handleDeletePolygon, handleDeleteCircle } = useMap(
    projects,
    filteredProjectsByPhases,
    mapParam,
  );

  useEffect(() => {
    if (polygonNames.length > 0 || circleNames.length > 0) {
      setOpen(true);
    }
  }, [polygonNames.length, circleNames.length]);

  const handleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  return (
    <div className='relative pointer-events-auto min-h-[40px] z-10 w-[240px]'>
      {/* Dropdown Content */}
      {open && (
        <div className='absolute bottom-full left-0 w-full bg-white max-h-[500px] drop-shadow-lg border border-bmgrey rounded-t-[30px] pt-4 flex flex-col overflow-hidden truncate'>
          <div className='overflow-y-scroll flex flex-col'>
            {[...polygonNames, ...circleNames].length < 1 ? (
              <span className='pl-8 py-2 text-bmblue'>
                {t(
                  'market.reports.map_controls.selection.no_polygons',
                  'Create some selection first',
                )}
              </span>
            ) : (
              <>
                {polygonNames.map((name) => (
                  <div
                    className='flex justify-between pr-8 hover:bg-gray-100'
                    onMouseEnter={() => setIsHovered && setIsHovered(name)}
                    onMouseLeave={() => setIsHovered && setIsHovered(null)}
                  >
                    <span className='pl-8 py-2 text-bmblue cursor-default'>
                      {name}
                    </span>
                    <XMarkIcon
                      width={24}
                      color='#BDBDBD'
                      className='stroke-2 cursor-pointer'
                      onClick={() => {
                        handleDeletePolygon(name);
                      }}
                    />
                  </div>
                ))}
                {circleNames.map((name) => (
                  <div
                    className='flex justify-between pr-8 hover:bg-gray-100'
                    onMouseEnter={() => setIsHovered && setIsHovered(name)}
                    onMouseLeave={() => setIsHovered && setIsHovered(null)}
                  >
                    <span className='pl-8 py-2 text-bmblue cursor-default'>
                      {name}
                    </span>
                    <XMarkIcon
                      width={24}
                      color='#BDBDBD'
                      className='stroke-2 cursor-pointer'
                      onClick={() => {
                        handleDeleteCircle(name);
                      }}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div
        className={clsx(
          'px-8 py-2 flex justify-between bg-white drop-shadow-xl gap-[64px] items-center border border-bmgrey h-full',
          !open ? 'rounded-[30px]' : 'rounded-bl-[30px] rounded-br-[30px]',
        )}
      >
        <div className='flex gap-[14px]'>
          <Square3Stack3DIcon width={24} color='#313B5E' className='stroke-2' />
          <span className='font-bold text-bmblue'>
            {t('market.reports.map_controls.selection', 'Selection')}
          </span>
        </div>
        <Popover
          content={
            <span>
              {t(
                `market.reports.map_controls.tooltips.${
                  !open ? 'maximize' : 'minimize'
                }`,
                !open ? 'Maximize' : 'Minimize',
              )}
            </span>
          }
        >
          <div className='flex gap-[14px]'>
            {!open ? (
              <ChevronDoubleUpIcon
                width={24}
                className='stroke-2 cursor-pointer relative'
                color='#BDBDBD'
                onClick={handleOpen}
              />
            ) : (
              <ChevronDoubleDownIcon
                width={24}
                className='stroke-2 cursor-pointer'
                color='#BDBDBD'
                onClick={handleOpen}
              />
            )}
          </div>
        </Popover>
      </div>
    </div>
  );
};
