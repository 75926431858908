import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useBreakpoints,
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { ProjectType } from '../../store/types';
import { VATSwitch } from '../VATSwitch/VATSwitch';
import { ProjectSelect } from '../ProjectSelect';
import { PriceUpdateExport } from './PriceUpdateExport';
import { ExpandedPricingUnitsResult } from '../../pages/PriceUpdate/PriceUpdatePage';

enum ColumnSwitchValue {
  PRICE,
  PRICE_PER_SM,
}

type Props = {
  exportData: ExpandedPricingUnitsResult;
};

export const PriceUpdateFilters: FC<Props> = ({ exportData }) => {
  const { isSmallScreen } = useBreakpoints();
  const { t } = useTranslation();
  const showPricePerSM = useStoreState(
    (state) => state.priceList.showPricePerSM,
  );
  const { areaUnit } = useRegionFormatting();
  const setShowPricePerSM = useStoreActions(
    (actions) => actions.priceList.setShowPricePerSM,
  );
  const handleChange = (value: SegmentedValue): void => {
    setShowPricePerSM(value === ColumnSwitchValue.PRICE_PER_SM);
  };
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  return (
    <>
      <VATSwitch />
      <ProjectSelect isPriceUpdate />
      <div>
        <Segmented
          value={
            showPricePerSM
              ? ColumnSwitchValue.PRICE_PER_SM
              : ColumnSwitchValue.PRICE
          }
          options={[
            {
              value: ColumnSwitchValue.PRICE,
              label: t(
                `common.${isRent ? 'rent' : 'price'}`,
                `${isRent ? 'Rent' : 'Price'}`,
              ),
            },
            {
              value: ColumnSwitchValue.PRICE_PER_SM,
              label: t(
                `common.${isRent ? 'rent' : 'price'}_per_unit`,
                `${isRent ? 'Rent' : 'Price'} per {{unit}}`,
                {
                  unit: areaUnit,
                },
              ),
            },
          ]}
          onChange={handleChange}
        />
      </div>
      {!isSmallScreen && (
        <div>
          <PriceUpdateExport exportData={exportData} />
        </div>
      )}
    </>
  );
};
