import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { PriceTrendEnum } from '../../../pages/PriceUpdate/PriceUpdatePage';
import {
  PercentageChange,
  UpdateDetails,
} from '../../../api/mockData/priceUpdateDataUpdated';

const INCREASE_TRESHOLD = 0.005;
const MAX_INCREASE_TRESHOLD = 0.1;
const MIN_DECREASE_TRESHOLD = -0.1;

interface UseRevenueChangeProps {
  priceTrend: PriceTrendEnum;
  revenue_change_percentage: number;
  avg_update_percentage: number;
  percentageChangeArr: PercentageChange[];
  setPercentageChangeArr: Dispatch<SetStateAction<PercentageChange[]>>;
  attributeDetails: {
    attribute_by_layout?: string | null;
    attribute_flag: string;
    attribute_name: string;
  };
  handleDisableAttribute: (attribute: Partial<UpdateDetails>) => void;
  partialAttribute: Partial<UpdateDetails>;
}

interface UseRevenueChangeReturn {
  setUpdatedChange: Dispatch<SetStateAction<number>>;
  updatedChange: number;
  canIncrease: () => boolean;
  canDecrease: () => boolean;
  increaseRevenueChange: () => void;
  decreaseRevenueChange: () => void;
  isValidInputValue: (value: number) => number;
}

export const useRevenueChange = ({
  priceTrend,
  revenue_change_percentage,
  avg_update_percentage,
  percentageChangeArr,
  setPercentageChangeArr,
  attributeDetails,
}: UseRevenueChangeProps): UseRevenueChangeReturn => {
  const { t } = useTranslation();
  const [updatedChange, setUpdatedChange] = useState(revenue_change_percentage);

  useEffect(() => {
    setUpdatedChange(revenue_change_percentage);
  }, [revenue_change_percentage]);

  const getIncreaseTrendUpperBound = (): number =>
    revenue_change_percentage + MAX_INCREASE_TRESHOLD;

  const getIncreaseTrendLowerBound = (): number => {
    if (revenue_change_percentage > Number(avg_update_percentage)) {
      return revenue_change_percentage - Number(avg_update_percentage);
    }
    return 0;
  };

  const getDecreaseTrendUpperBound = (): number =>
    revenue_change_percentage + MIN_DECREASE_TRESHOLD;

  const getDecreaseTrendLowerBound = (): number => {
    if (revenue_change_percentage < Number(avg_update_percentage)) {
      return revenue_change_percentage - Number(avg_update_percentage);
    }
    return 0;
  };

  const isValidInputValue = (value: number): number => {
    const roundedValue = Number(value.toFixed(1));
    if (priceTrend === PriceTrendEnum.INCREASE) {
      if (value >= getIncreaseTrendUpperBound()) {
        message.info(
          t(
            'price_update.update_tag.effect_maximized',
            'This effect is fully maximized. To increase the price further, change other features.',
          ),
        );
        return getIncreaseTrendUpperBound();
      }
      if (value <= getIncreaseTrendLowerBound()) {
        message.info(
          t(
            'price_update.update_tag.effect_minimized',
            'This effect is fully minimized. To decrease the price further, change other features.',
          ),
        );
        return getIncreaseTrendLowerBound();
      }
      return value;
    }
    if (value <= getDecreaseTrendUpperBound()) {
      message.info(
        t(
          'price_update.update_tag.effect_minimized',
          'This effect is fully minimized. To decrease the price further, change other features.',
        ),
      );
      return getDecreaseTrendUpperBound();
    }
    if (value >= getDecreaseTrendLowerBound()) {
      message.info(
        t(
          'price_update.update_tag.effect_maximized',
          'This effect is fully maximized. To increase the price further, change other features.',
        ),
      );
      return getDecreaseTrendLowerBound();
    }
    return value;
  };

  const canIncrease = useCallback((): boolean => {
    if (priceTrend === PriceTrendEnum.INCREASE) {
      return updatedChange < revenue_change_percentage + MAX_INCREASE_TRESHOLD;
    }
    if (revenue_change_percentage < Number(avg_update_percentage)) {
      return (
        updatedChange + INCREASE_TRESHOLD <
        revenue_change_percentage - Number(avg_update_percentage)
      );
    }
    return updatedChange > 0 + INCREASE_TRESHOLD;
  }, [
    priceTrend,
    updatedChange,
    revenue_change_percentage,
    avg_update_percentage,
    MAX_INCREASE_TRESHOLD,
    INCREASE_TRESHOLD,
  ]);

  const canDecrease = useCallback((): boolean => {
    if (priceTrend === PriceTrendEnum.DECREASE) {
      return updatedChange > revenue_change_percentage + MIN_DECREASE_TRESHOLD;
    }
    if (revenue_change_percentage > Number(avg_update_percentage)) {
      return (
        updatedChange - INCREASE_TRESHOLD >
        revenue_change_percentage - Number(avg_update_percentage)
      );
    }
    return updatedChange > 0 + INCREASE_TRESHOLD;
  }, [
    priceTrend,
    updatedChange,
    revenue_change_percentage,
    avg_update_percentage,
    MIN_DECREASE_TRESHOLD,
    INCREASE_TRESHOLD,
  ]);

  const handleChange = useCallback(() => {
    const { attribute_by_layout, attribute_flag, attribute_name } =
      attributeDetails;

    const containsAttributeIndex = percentageChangeArr?.findIndex(
      (change) =>
        change.attribute_by_layout === attribute_by_layout &&
        change.attribute_flag === attribute_flag &&
        change.attribute_name === attribute_name,
    );

    if (containsAttributeIndex !== -1) {
      setPercentageChangeArr((prevArr) =>
        prevArr?.map((change, index) =>
          index === containsAttributeIndex
            ? {
                ...change,
                new_revenue_change_percentage: updatedChange,
              }
            : change,
        ),
      );
      return;
    }

    if (revenue_change_percentage === updatedChange) {
      return;
    }

    const newPercentageChange = {
      attribute_by_layout: attribute_by_layout
        ? String(attribute_by_layout)
        : null,
      attribute_flag: String(attribute_flag),
      attribute_name: String(attribute_name),
      old_revenue_change_percentage: revenue_change_percentage,
      new_revenue_change_percentage: updatedChange,
    };

    setPercentageChangeArr((prevArr) => [...prevArr, newPercentageChange]);
  }, [
    updatedChange,
    percentageChangeArr,
    setPercentageChangeArr,
    attributeDetails,
    revenue_change_percentage,
  ]);

  useEffect(() => {
    handleChange();
  }, [updatedChange]);

  const increaseRevenueChange = useCallback((): void => {
    if (canIncrease()) {
      setUpdatedChange((change) => change + INCREASE_TRESHOLD);
    }
  }, [canIncrease]);

  const decreaseRevenueChange = useCallback((): void => {
    if (canDecrease()) {
      setUpdatedChange((change) => change - INCREASE_TRESHOLD);
    }
  }, [canDecrease]);

  return {
    setUpdatedChange,
    updatedChange,
    canIncrease,
    canDecrease,
    increaseRevenueChange,
    decreaseRevenueChange,
    isValidInputValue,
  };
};
