import { useMemo } from 'react';
import { useStoreState } from '.';
import { Availability } from '../store/types';
import { PriceComparisonData } from '../api/types';
import { ProjectComparisonData } from '../components/project/Comparison';
import dayjs from '../utils/dayjs';

interface Data {
  availability: string;
}

export type PaginatedItems<T> = Record<number, T[]>;

/**
 * Hook to map reserved data to sold/available units based on user settings
 */
export const useReservedData = (): {
  mapReservedData: <T extends Data>(data: T[]) => T[];
  transformReserved: (data: (PriceComparisonData | ProjectComparisonData)[]) => (PriceComparisonData | ProjectComparisonData)[];
  transformCompetitorsData: (data: PriceComparisonData[], competitors?: number[]) => PriceComparisonData[];
  paginateItemsByYearRange: <T extends { date: string }>(items: T[]) => PaginatedItems<T> | null;
} => {
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const transformReserved = (data: (PriceComparisonData | ProjectComparisonData)[]): (PriceComparisonData | ProjectComparisonData)[] =>
    data.reduce<(PriceComparisonData | ProjectComparisonData)[]>((acc, layoutData) => {
      const mappedReservedValue = isSold
        ? Availability.SOLD
        : Availability.AVAILABLE;
      const current = {
        ...layoutData,
        availability: layoutData.availability === 'reserved' ? mappedReservedValue : layoutData.availability,
      };
      const index = acc.findIndex((x) => x.availability === current.availability && x.layout === current.layout);
      if (index > -1) {
        const existingItem = acc[index];
        const totalCount = existingItem.market.count + current.market.count;
        const totalPrice = existingItem.market.price + current.market.price;
        acc[index].market = {
          count: totalCount,
          price: totalPrice,
          price_per_sm: (existingItem.market.price_per_sm * existingItem.market.count + current.market.price_per_sm * current.market.count) / totalCount,
        };
      } else {
        acc.push(current);
      }
      return acc;
  }, []);

  const mapReservedData = useMemo(
    () =>
      <T extends Data>(data: T[]): T[] => {
        const mappedReservedValue = isSold
          ? Availability.SOLD
          : Availability.AVAILABLE;
        return data.map((d) => ({
          ...d,
          availability:
            d.availability === Availability.RESERVED
              ? mappedReservedValue
              : d.availability,
        }));
      },
    [isSold],
  );

  const transformCompetitorsData = (data: PriceComparisonData[], competitors?: number[]): PriceComparisonData[] => {
    if (competitors && competitors.length > 0) {
      const projectData = data.filter((d) => d.project_name === 'current_dashboard');
      const competitorsData = data.filter((d) => d.project_name !== 'current_dashboard');
      return [...transformReserved(projectData), ...competitorsData] as PriceComparisonData[];
    }
    return transformReserved(data) as PriceComparisonData[];
  };

  const paginateItemsByYearRange = <T extends { date: string }>(
    items: T[],
  ): PaginatedItems<T> | null => {
    if (!items.length) return null;

    const sortedItems = items.sort(
      (a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf(),
    );

    const pages: PaginatedItems<T> = {};
    let currentPage = 1;
    let currentStartDate = dayjs();
    let currentEndDate = currentStartDate.subtract(12, 'month');

    sortedItems.forEach((item) => {
      const itemDate = dayjs(item.date);

      if (itemDate.isBefore(currentEndDate)) {
        currentPage++;
        currentStartDate = currentEndDate;
        currentEndDate = currentStartDate.subtract(12, 'month');
      }

      if (!pages[currentPage]) {
        pages[currentPage] = [];
      }

      pages[currentPage].push(item);
    });

    return pages;
  };

  return { mapReservedData, transformReserved, transformCompetitorsData, paginateItemsByYearRange };
};
