import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting } from '../../../../../../hooks';

interface Props {
  parking_indoor_price?: number | null;
  parking_outdoor_price?: number | null;
}

export const Parking: FC<Props> = ({
  parking_indoor_price,
  parking_outdoor_price,
}) => {
  const { formatCurrency } = useRegionFormatting();
  const { t } = useTranslation();

  if (!parking_indoor_price && !parking_outdoor_price) {
    return <span className='font-bold'>-</span>;
  }

  return (
    <span className='font-bold'>
      {`${
        parking_outdoor_price
          ? `${t('common.from', 'From')} ${formatCurrency(
              parking_outdoor_price,
            )}`
          : ''
      }${
        !parking_outdoor_price && parking_indoor_price
          ? `${t('common.from', 'From')} ${formatCurrency(
              parking_indoor_price,
            )}`
          : ''
      }`}
    </span>
  );
};
