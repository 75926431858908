/** @jsxImportSource @emotion/react */
import { FC, MouseEvent } from 'react';
import { Popover, Checkbox, Button, message } from 'antd';
import { css } from '@emotion/react/macro';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { ExportToCsv, Options } from 'export-to-csv';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useFlatLayout, useRegionFormatting, useStoreActions, useStoreState } from '../../../../../hooks';
import { getFlatsFromS3 } from '../../../../../api';
import { round } from '../../../../../utils/formatters';

const options: Options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type Props = {
  showValues?: boolean;
  onDownloadPNG?: () => void;
  s3url: string;
  date?: string;
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
};

export const csvExporter = new ExportToCsv(options);

export const SupplyHistoryMonthExport: FC<Props> = ({ showValues = false, onDownloadPNG, s3url, date }) => {
  const { displayValues } = useStoreState((state) => state.filters);
  const { setDisplayValues } = useStoreActions((action) => action.filters);
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { getCurrency, getVATText } = useRegionFormatting();
  const { getLayout } = useFlatLayout();

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    handlePropagation(e);
    setDisplayValues(!displayValues);
  };

  const handleButtonClick = async (exportFormat: string): Promise<void> => {
    message.loading(t('market.export.preparing_data'), 0);
    if (paramsPM) {
      getFlatsFromS3(s3url)
        .then(({ data: flats }) => {
          const updatedExportData = flats.map(
            ({
              project_name,
              developer,
              address,
              unique_string: id,
              building,
              category,
              orientation,
              DOM,
              availability,
              exterior_area,
              floor,
              floor_area,
              layout,
              price,
              price_per_sm,
              total_area,
              first_seen,
              parking_indoor_price,
              parking_outdoor_price,
              url,
            }) => ({
              project: project_name,
              developer,
              address,
              id,
              building: building ?? '',
              category,
              availability: t(`enums.state.${availability.toLowerCase()}`),
              exterior_area: exterior_area ?? 0,
              floor: floor ?? '',
              floor_area: floor_area ?? '',
              layout: getLayout(layout.toLowerCase()),
              price: price ? round(price || 0, 2) : '',
              price_per_sm: price_per_sm ? round(price_per_sm || 0, 2) : '',
              parking_indoor_price: parking_indoor_price ? round(parking_indoor_price || 0, 2) : '',
              parking_outdoor_price: parking_outdoor_price ? round(parking_outdoor_price || 0, 2) : '',
              total_area: total_area ?? '',
              orientation: orientation ?? '',
              first_seen,
              days_on_market: DOM,
              currency: getCurrency(),
              url: url ?? '',
            }),
          );

          const filename = `${dayjs(date).format('YYYYMM')}-added_units${getVATText()}`;

          if (exportFormat === 'CSV') {
            csvExporter.options.filename = `${filename}${getVATText()}`;
            csvExporter.generateCsv(updatedExportData);
          } else {
            const worksheet = XLSX.utils.json_to_sheet(updatedExportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${filename}${getVATText()}.xlsx`);
          }

          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Popover
      overlayClassName='report-actions'
      placement='bottom'
      content={
        <div css={styles.container}>
          {showValues && (
            <div className='actions' role='presentation' onClick={handleClick}>
              <div>
                {t('common.display_values')}
              </div>
              <Checkbox checked={displayValues} />
            </div>
          )}
          {onDownloadPNG && (
            <Button
              css={styles.download}
              icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
              onClick={onDownloadPNG}
              style={{ marginTop: showValues ? 10 : 0 }}
              type='text'
            >
              PNG
            </Button>
          )}
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('CSV')}
            type='text'
          >
            {t('market.button.export_csv')}
          </Button>
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('XLSX')}
            type='text'
          >
            {t('market.button.export_xlsx')}
          </Button>
        </div>
      }
    >
      <div
        role='button'
        tabIndex={0}
        className='actions-download-left'
        onClick={handlePropagation}
      >
        <ArrowDownTrayIcon />
      </div>
    </Popover>
  );
};
