/** @jsxImportSource @emotion/react */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Affix, Button, Card, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  BuildingOfficeIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import { Page } from '../../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../../hooks';
import styles from './PriceUpdate.module.css';
import { PriceUpdateFilters } from '../../components/priceUpdate/PriceUpdateFilters';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';
import { DetailPreviewCard } from '../../components/priceListDetail/DetailPreviewCard/DetailPreviewCard';
import { transformPricingListData } from '../../utils/utils';
import { SubtitleContainerUpdated } from './SubtitleContainer/SubtitleContainerUpdated';
import {
  UpdateType,
  Flat,
  UpdateDetails,
  PercentageChange,
} from '../../api/mockData/priceUpdateDataUpdated';
import { ProjectType } from '../../store/types';
import { Unit } from '../../api/mockData/priceListData';
import { updatePriceListUserSeen } from '../../api';
import { round } from '../../utils/formatters';
import { PriceUpdateTable } from '../../components/priceUpdate/priceUpdateTable';
import { PanelHeader } from './PanelHeader';

export enum PriceTrendEnum {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export type ExpandedPricingUnitsResult = {
  flats: Flat[];
  addedKeys: string[];
};

export const PriceUpdatePage: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [exportData, setExportData] = useState<ExpandedPricingUnitsResult>({
    flats: [],
    addedKeys: [],
  });
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const selectedPricingData = useStoreState(
    (state) => state.priceList.selectedPricingUpdateData,
  );
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const updateData = useStoreState(
    (state) => state.priceList.updateDataUpdated,
  );
  const { projectId, isLoading } = useStoreState((state) => state.filters);
  const fetchUpdateData = useStoreActions(
    (actions) => actions.priceList.fetchUpdateDataUpdated,
  );
  const VAT_included = useStoreState(
    (state) => state.user.profile?.VAT_included,
  );
  const updateLocalUserSeen = useStoreActions(
    (actions) => actions.filters.updateLocalUserSeen,
  );
  const { updateAttributes } = useStoreActions((actions) => actions.priceList);
  const projectUpdate = useMemo(
    () =>
      overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );
  const [showCard, setShowCard] = useState<boolean>(
    !!selectedPricingData || false,
  );

  const {
    unavailable_units = 0,
    total_units = 0,
    sale_start = null,
    sale_end = null,
    update_date = null,
    revenue_change_percentage = 0,
    current_revenue = 0,
    time_progress_percentage = 0,
    revenue_change = 0,
    recommended_revenue = 0,
  } = updateData?.key_metrics || {};

  const positiveUpdates = updateData?.updates?.positive as UpdateType;
  const negativeUpdates = updateData?.updates?.negative as UpdateType;
  const hiddenUpdates = updateData?.updates?.hidden as UpdateDetails[];

  const areTherePositiveUpdates =
    !!positiveUpdates?.disabled_updates.length ||
    !!positiveUpdates?.large_updates.length ||
    !!positiveUpdates?.small_updates.length;
  const areThereNegativeUpdates =
    !!negativeUpdates?.disabled_updates.length ||
    !!negativeUpdates?.large_updates.length ||
    !!negativeUpdates?.small_updates.length;

  const flats = updateData?.units as Flat[];

  const expandPricingUnitsWithUpdates = (
    flatsData: Flat[],
    negativeLargeData: UpdateDetails[],
    negativeSmallData: UpdateDetails[],
    positiveLargeData: UpdateDetails[],
    positiveSmallData: UpdateDetails[],
    hiddenUpdatesData: UpdateDetails[],
  ): { flats: Flat[]; addedKeys: string[] } => {
    const addedKeys = new Set<string>();

    const applyUpdates = (units: Flat[], updates: UpdateDetails[]): Flat[] => {
      if (!updates) {
        return units;
      }

      units.forEach((unit) => {
        if (unit.availability === 'available' && !unit.is_update_frozen) {
          unit.update_sale_speed_effect = round(updateData?.updates.sale_speed_effect || 0, 4) || null;
        }
      });

      return updates.reduce((updatedUnits, update) => {
        const attributeName = `update_${update.attribute_name}`;
        addedKeys.add(attributeName);

        update.affected_units.forEach((affectedUnit) => {
          const matchingUnit = updatedUnits.find(
            (unit) => unit.id === affectedUnit.id,
          );
          if (matchingUnit && !matchingUnit.is_update_frozen && matchingUnit.availability === 'available') {
            const roundedValue = round(
              affectedUnit.attribute_update.percentage,
              4,
            );
            matchingUnit[attributeName] =
              roundedValue !== 0 ? roundedValue : null;
          }
        });
        return updatedUnits;
      }, units);
    };

    let updatedUnits = flatsData.map((unit) => ({ ...unit }));
    updatedUnits = applyUpdates(updatedUnits, negativeLargeData);
    updatedUnits = applyUpdates(updatedUnits, negativeSmallData);
    updatedUnits = applyUpdates(updatedUnits, positiveLargeData);
    updatedUnits = applyUpdates(updatedUnits, positiveSmallData);
    updatedUnits = applyUpdates(updatedUnits, hiddenUpdatesData);

    return {
      flats: updatedUnits,
      addedKeys: [...addedKeys, 'update_sale_speed_effect'],
    };
  };

  useEffect(() => {
    if (flats && negativeUpdates && positiveUpdates) {
      setExportData(
        expandPricingUnitsWithUpdates(
          flats,
          negativeUpdates.large_updates,
          negativeUpdates.small_updates,
          positiveUpdates.large_updates,
          positiveUpdates.small_updates,
          hiddenUpdates,
        ),
      );
    }
  }, [flats, hiddenUpdates, negativeUpdates, positiveUpdates]);

  const [disabledAttributes, setDisabledAttributes] = useState<
    Partial<UpdateDetails>[]
  >([]);

  const [percentageChange, setPercentageChange] = useState<PercentageChange[]>(
    [],
  );

  const filteredPercentageChange = useMemo(
    () =>
      percentageChange?.filter(
        (change) =>
          change.new_revenue_change_percentage.toFixed(4) !==
          change.old_revenue_change_percentage.toFixed(4),
      ),
    [percentageChange],
  );

  const handleResetUpdate = (resetPercentageChange: PercentageChange[]): void => {
    updateAttributes({
      projectId: projectId ?? 0,
      disabled_attributes: disabledAttributes,
      percentage_change: resetPercentageChange,
    });
  };

  const latestState = useRef({ disabledAttributes, filteredPercentageChange });

  const initialDisabledAttributes = useRef(true);
  useEffect(() => {
    if (updateData && initialDisabledAttributes.current) {
      initialDisabledAttributes.current = false;
      setPercentageChange(updateData?.key_metrics?.percentage_change ?? []);
      setDisabledAttributes(updateData?.key_metrics?.disabled_attributes ?? []);
    }
  }, [updateData]);

  const debounceDisableAttributes = useCallback(
    debounce((updatedAttributes) => {
      updateAttributes({
        projectId: projectId ?? 0,
        disabled_attributes: updatedAttributes,
        percentage_change: latestState.current.filteredPercentageChange,
      });
    }, 1000),
    [projectId, updateAttributes],
  );

  const handleDisableAttribute = (attribute: Partial<UpdateDetails>): void => {
    setDisabledAttributes((currentAttributes) => {
      const updatedAttributes = [...currentAttributes, attribute];
      debounceDisableAttributes(updatedAttributes);
      return updatedAttributes;
    });
  };

  const handleEnableAttribute = (attribute: Partial<UpdateDetails>): void => {
    setDisabledAttributes((currentAttributes) => {
      const updatedAttributes = currentAttributes?.filter((attr) => {
        const attrString = `${attr.attribute_flag}${attr.attribute_by_layout}${attr.attribute_name}`;
        const enabledAttrString = `${attribute.attribute_flag}${attribute.attribute_by_layout}${attribute.attribute_name}`;
        return attrString !== enabledAttrString;
      });
      debounceDisableAttributes(updatedAttributes);
      return updatedAttributes;
    });
  };

  const isDisabledAttribute = (attribute: Partial<UpdateDetails>): boolean =>
    disabledAttributes.some(
      (attr) =>
        attr.attribute_flag === attribute.attribute_flag &&
        attr.attribute_name === attribute.attribute_name &&
        attr.attribute_by_layout === attribute.attribute_by_layout,
    );

  const firstUpdate = useRef(true);

  useEffect(() => {
    latestState.current = { disabledAttributes, filteredPercentageChange };
  }, [disabledAttributes, filteredPercentageChange]);

  const debouncedUpdateAttributes = useCallback(
    debounce((updatedAttributes) => {
      updateAttributes({
        projectId: projectId ?? 0,
        disabled_attributes: updatedAttributes,
        percentage_change: latestState.current.filteredPercentageChange,
      });
      setPercentageChange([]);
    }, 2000),
    [latestState.current.filteredPercentageChange],
  );

  useEffect(() => {
    const handleSave = async (): Promise<void> => {
      if (
        !firstUpdate.current &&
        latestState.current.filteredPercentageChange.length
      ) {
        debouncedUpdateAttributes(disabledAttributes);
      }
      firstUpdate.current = false;
    };

    handleSave();
    return () => debouncedUpdateAttributes.cancel();
  }, [disabledAttributes, filteredPercentageChange]);

  const mapUpdatesWithFlats = (
    updates: UpdateDetails[],
    flatsForMap: Flat[],
  ): UpdateDetails[] =>
    updates?.map((update) => {
      const flatsForUpdate = update.affected_units
        ?.map((affectedUnit) => {
          const flatForUpdate = flatsForMap?.find(
            (flat) => flat.id === affectedUnit.id,
          );

          if (flatForUpdate) {
            return {
              ...flatForUpdate,
              attribute_update_percentage:
                affectedUnit.attribute_update.percentage ?? null,
            };
          }
          return undefined;
        })
        ?.filter(
          (flat): flat is Flat & { attribute_update_percentage: number } =>
            flat !== undefined,
        );

      const mappedFlats = flatsForUpdate?.map((flat) => ({
        ...flat,
        layout: `layout_${flat.layout}`,
        total_update: flat.update_percentage * 100,
        feature_update: flat.attribute_update_percentage * 100,
        internal_id: flat.name,
      }));

      return {
        ...update,
        flats: mappedFlats,
      };
    });

  const positiveLargeUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.large_updates,
    flats,
  );
  const positiveDisabledUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.disabled_updates,
    flats,
  );
  const positiveSmallUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.small_updates,
    flats,
  );
  const negativeLargeUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.large_updates,
    flats,
  );
  const negativeDisabledUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.disabled_updates,
    flats,
  );
  const negativeSmallUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.small_updates,
    flats,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && projectId && projectUpdate) {
      fetchUpdateData({ projectId });
    }
    if (projectUpdate && !projectUpdate.user_seen) {
      updateLocalUserSeen(projectUpdate.id);
      updatePriceListUserSeen({ price_list_update_id: projectUpdate.id });
    }
  }, [fetchUpdateData, isLoading, projectId, projectUpdate, VAT_included]);

  return (
    <Page
      pageClassname='price-update'
      filters={
        isDashboardEnabled && <PriceUpdateFilters exportData={exportData} />
      }
      title={
        isDashboardEnabled ? (
          <div className='reports-title'>
            <span>{t('price_list.title')}</span>
            <span>/</span>
            <span className='active'>
              {t('price_update.title', 'Price Update')}
            </span>
          </div>
        ) : (
          <span>{t('price_list.title')}</span>
        )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isDashboardEnabled ? (
        !updateData ? (
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {t('price_update.loading_message')}
          </div>
        ) : (
          <Row className={styles.contentContainer} gutter={[16, 16]}>
            <Col
              xs={24}
              md={showCard ? 18 : 23}
              className={styles.contentContainer}
            >
              <Card className={styles.tableWrapper}>
                <div className='flex flex-col overflow-y-scroll'>
                  <div className={styles.cardWrapper}>
                    <h1 className={styles.title}>
                      {t('price_update.title', 'Price Update')}
                    </h1>
                    <div className={styles.titleWrapper}>
                      <div className={styles.projectIcon}>
                        <BuildingOfficeIcon />
                      </div>
                      <div className={styles.projectName}>
                        {projectUpdate?.name || 'Mock Project'}
                      </div>
                    </div>
                  </div>
                  <SubtitleContainerUpdated
                    revenue_change_percentage={revenue_change_percentage}
                    sale_end={sale_end ?? ''}
                    sale_start={sale_start ?? ''}
                    total_units={total_units}
                    unavailable_units={unavailable_units}
                    update_date={update_date ?? ''}
                    current_revenue={current_revenue}
                    revenue_change={revenue_change}
                    recommended_revenue={recommended_revenue}
                    time_progress_percentage={time_progress_percentage}
                  />
                  <div className='pt-12'>
                    {areTherePositiveUpdates && (
                      <>
                        <div className='flex gap-4 px-6'>
                          <ArrowTrendingUpIcon className='w-10' />
                          <span className='font-semibold text-[2.4rem]'>
                            {isRent
                              ? t('price_list.rent_increase')
                              : t(
                                  'price_list.price_increase',
                                  'Price Increases',
                                )}
                          </span>
                        </div>
                        <div className='px-[16px]'>
                          {!!(positiveLargeUpdatesWithFlats || []).length && (
                            <PriceUpdateTable
                              priceTrend={PriceTrendEnum.INCREASE}
                              // @ts-ignore TODO: Fix typing
                              data={positiveLargeUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                              handleResetUpdate={handleResetUpdate}
                            />
                          )}
                        </div>
                        <Collapse
                          ghost
                          accordion
                          expandIconPosition='start'
                          className='pb-[5rem] w-full'
                        >
                          <Collapse.Panel
                            key='disabled-updates'
                            className='w-full pt-6'
                            header={
                              <PanelHeader
                                title={t(
                                  'price_updates.subtitle.disabled_updates',
                                )}
                                tooltip={t(
                                  'price_updates.subtitle.disabled_updates.tooltip',
                                )}
                                updates={positiveDisabledUpdatesWithFlats}
                              />
                            }
                          >
                            {!!(positiveDisabledUpdatesWithFlats || [])
                              .length && (
                              <PriceUpdateTable
                                priceTrend={PriceTrendEnum.INCREASE}
                                disabledUpdatesGroup
                                // @ts-ignore TODO: Fix typing
                                data={positiveDisabledUpdatesWithFlats}
                                handleDisableAttribute={handleDisableAttribute}
                                handleEnableAttribute={handleEnableAttribute}
                                isDisabledAttribute={isDisabledAttribute}
                                percentageChange={percentageChange}
                                setPercentageChange={setPercentageChange}
                                filteredPercentageChange={
                                  filteredPercentageChange
                                }
                                handleResetUpdate={handleResetUpdate}
                              />
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel
                            className='w-full'
                            key='small-updates'
                            header={
                              <PanelHeader
                                title={t(
                                  'price_updates.subtitle.small_updates',
                                )}
                                tooltip={t(
                                  'price_updates.subtitle.small_updates.tooltip',
                                )}
                                updates={positiveSmallUpdatesWithFlats}
                              />
                            }
                          >
                            {!!(positiveSmallUpdatesWithFlats || []).length && (
                              <PriceUpdateTable
                                priceTrend={PriceTrendEnum.INCREASE}
                                // @ts-ignore TODO: Fix typing
                                data={positiveSmallUpdatesWithFlats}
                                handleDisableAttribute={handleDisableAttribute}
                                handleEnableAttribute={handleEnableAttribute}
                                isDisabledAttribute={isDisabledAttribute}
                                percentageChange={percentageChange}
                                setPercentageChange={setPercentageChange}
                                filteredPercentageChange={
                                  filteredPercentageChange
                                }
                                handleResetUpdate={handleResetUpdate}
                              />
                            )}
                          </Collapse.Panel>
                        </Collapse>
                      </>
                    )}
                    {areThereNegativeUpdates && (
                      <div className='flex flex-col flex-1'>
                        <div className='flex gap-4 px-6'>
                          <ArrowTrendingDownIcon className='w-10' />
                          <span className='font-semibold text-[2.4rem]'>
                            {isRent
                              ? t('price_list.rent_decrease')
                              : t(
                                  'price_list.price_decrease',
                                  'Price Decreases',
                                )}
                          </span>
                        </div>
                        <div className='px-[16px]'>
                          {!!(negativeLargeUpdatesWithFlats || []).length && (
                            <PriceUpdateTable
                              priceTrend={PriceTrendEnum.DECREASE}
                              // @ts-ignore TODO: Fix typing
                              data={negativeLargeUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                              handleResetUpdate={handleResetUpdate}
                            />
                          )}
                        </div>
                        <Collapse
                          ghost
                          accordion
                          expandIconPosition='start'
                          className='pb-[5rem] w-full'
                        >
                          {!!(negativeDisabledUpdatesWithFlats || [])
                            .length && (
                            <Collapse.Panel
                              key='disabled-updates'
                              className='w-full pt-6'
                              header={
                                <PanelHeader
                                  title={t(
                                    'price_updates.subtitle.disabled_updates',
                                  )}
                                  tooltip={t(
                                    'price_updates.subtitle.disabled_updates.tooltip',
                                  )}
                                  updates={negativeDisabledUpdatesWithFlats}
                                />
                              }
                            >
                              <PriceUpdateTable
                                priceTrend={PriceTrendEnum.DECREASE}
                                disabledUpdatesGroup
                                // @ts-ignore TODO: Fix typing
                                data={negativeDisabledUpdatesWithFlats}
                                handleDisableAttribute={handleDisableAttribute}
                                handleEnableAttribute={handleEnableAttribute}
                                isDisabledAttribute={isDisabledAttribute}
                                percentageChange={percentageChange}
                                setPercentageChange={setPercentageChange}
                                filteredPercentageChange={
                                  filteredPercentageChange
                                }
                                handleResetUpdate={handleResetUpdate}
                              />
                            </Collapse.Panel>
                          )}
                          {!!(negativeSmallUpdatesWithFlats || []).length && (
                            <Collapse.Panel
                              className='w-full'
                              key='small-updates'
                              header={
                                <PanelHeader
                                  title={t(
                                    'price_updates.subtitle.small_updates',
                                  )}
                                  tooltip={t(
                                    'price_updates.subtitle.small_updates.tooltip',
                                  )}
                                  updates={negativeSmallUpdatesWithFlats}
                                />
                              }
                            >
                              <PriceUpdateTable
                                priceTrend={PriceTrendEnum.DECREASE}
                                // @ts-ignore TODO: Fix typing
                                data={negativeSmallUpdatesWithFlats}
                                handleDisableAttribute={handleDisableAttribute}
                                handleEnableAttribute={handleEnableAttribute}
                                isDisabledAttribute={isDisabledAttribute}
                                percentageChange={percentageChange}
                                setPercentageChange={setPercentageChange}
                                filteredPercentageChange={
                                  filteredPercentageChange
                                }
                                handleResetUpdate={handleResetUpdate}
                              />
                            </Collapse.Panel>
                          )}
                        </Collapse>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Col>
            {!isSmallScreen && (
              <Col
                xs={24}
                md={showCard ? 6 : 1}
                className={styles.rightColumn}
                ref={setContainer}
              >
                <Affix
                  className={styles.affix}
                  offsetTop={1}
                  target={() => container}
                >
                  <div className={styles.affix}>
                    {showCard && !isSmallScreen ? (
                      <DetailPreviewCard
                        showDetailButton
                        onHide={() => setShowCard(false)}
                        data={transformPricingListData(
                          selectedPricingData as Unit,
                        )}
                      />
                    ) : (
                      !isSmallScreen && (
                        <Col md={1}>
                          <Button
                            onClick={() => setShowCard(true)}
                            className={styles.iconBtn}
                          >
                            <ChevronDoubleLeftIcon className='w-8 stroke-2' />
                          </Button>
                        </Col>
                      )
                    )}
                  </div>
                </Affix>
              </Col>
            )}
          </Row>
        )
      ) : (
        <SubscribePage featureType='dashboard' />
      )}
    </Page>
  );
};
