import { Col, Modal, Row, Segmented } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { SegmentedValue } from 'antd/lib/segmented';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SupplyHistoryData } from '../SupplyHistoryChart';
import { useRegionFormatting, useStoreState } from '../../../../../hooks';
import { SupplyHistoryMonthData } from '../../../../../api/types';
import { fetchSupplyHistoryMonthData } from '../../../../../api';
import { Card } from '../../../../Card';
import { SupplyHistoryModalTable } from './SupplyHistoryModalTable';
import styles from './SupplyHistoryModal.module.css';
import { Chevron } from '../../../../secondaryMarket/blocks/Overview/Chevron';
import { downloadPNG } from '../../../../../pages/market/pngDownload';
import { getReportExportFileName } from '../../../../../utils/utils';
import { SupplyHistoryMonthExport } from './SupplyHistoryMonthExport';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  modalData: SupplyHistoryData | null;
}

export const SupplyHistoryModal: FC<Props> = ({ open, setOpen, modalData }) => {
  const [data, setData] = useState<SupplyHistoryMonthData | null>(null);
  const [filterValue, setFilterValue] = useState<'projects' | 'layouts'>('projects');
  const { formatCurrency } = useRegionFormatting();
  const [isLoading, setIsLoading] = useState(true);
  const { reserved_as_sold, VAT_included } = useStoreState((state) => state.user.profile) || {};
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { t } = useTranslation();
  const pngRef = useRef(null);

  const onChangeFilter = (value: SegmentedValue): void => {
    setFilterValue(value as 'projects' | 'layouts');
  };

  useEffect(() => {
    if (!modalData || !paramsPM) {
      return;
    }

    setIsLoading(true);
    setData(null);

    fetchSupplyHistoryMonthData(
      paramsPM.dashboardId,
      paramsPM.date,
      modalData.date,
      'new',
      reserved_as_sold || false,
      VAT_included || false,
    )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [modalData, paramsPM, reserved_as_sold, VAT_included]);

  const fileName = getReportExportFileName(
    paramsPM?.name || 'report',
    t('daily_news.kpi.supply'),
  );

  return (
    <Modal
      width={700}
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
      className='supply-history-modal'
    >
      <div className='h-[100%]' ref={pngRef}>
        <Card
          className={styles.modalCard}
          loading={isLoading}
        >
        {data && (
          <>
            <SupplyHistoryMonthExport onDownloadPNG={() => downloadPNG(fileName, pngRef)} s3url={data.s3url} date={modalData?.date} />
            <Row className='reports_overview_header'>
              <Col xs={6}>
                <Row justify='center' className='title bold'>
                  {t('daily_news.kpi.supply')}
                </Row>
                <Row justify='center' className='icon'>
                  <div className='supply daily_news_kpi_icon'>
                    <img
                      src='/assets/building_icon_dark_blue_compressed.png'
                      alt='house'
                    />
                  </div>
                </Row>
              </Col>
              <Col xs={9}>
                <Row justify='center'>
                  {t('supply_history.added_units', 'Added units')}
                </Row>
                <Row justify='center' className='title big'>
                  {data?.total_units}
                </Row>
                <Row justify='center' className='subtitle'>
                  {data?.growth_total_units && <Chevron number={data?.growth_total_units} />}
                </Row>
                <Row justify='center' className='font-semibold text-bmgray'>
                  {dayjs(modalData?.date).format('MM/YYYY')}
                </Row>
              </Col>
              <Col xs={9}>
                <Row justify='center'>
                  {t('daily_news.kpi.price_per_sm')}
                </Row>
                <Row justify='center' className='title big'>
                  {formatCurrency(data?.average_price_per_sm)}
                </Row>
                <Row justify='center' className='subtitle'>
                  {data?.growth_price_per_sm && <Chevron number={data?.growth_price_per_sm || 0} />}
                </Row>
                <Row justify='center' className='font-semibold text-bmgray'>
                  {dayjs(modalData?.date).format('MM/YYYY')}
                </Row>
              </Col>
            </Row>
            <Segmented
              value={filterValue}
              onChange={onChangeFilter}
              className='mb-4'
              options={[
                { value: 'projects', label: 'Projects' },
                { value: 'layouts', label: 'Layouts' },
              ]}
            />
            <SupplyHistoryModalTable loading={isLoading} tableData={data} filterValue={filterValue} />
          </>
        )}
        </Card>
      </div>
    </Modal>
  );
};
