/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { ExpandedPricingUnitsResult } from '../../pages/PriceUpdate/PriceUpdatePage';
import { slugifyString } from '../../utils/utils';

const options: Options = {
  filename: '',
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
};

export const csvExporter = new ExportToCsv(options);

type Props = {
  exportData: ExpandedPricingUnitsResult;
};

export const PriceUpdateExport: FC<Props> = ({ exportData }) => {
  const { isLoading } = useStoreState((state) => state.global);
  const { getCurrency, getVATText, applyRounding } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { exportPriceUpdate, selectedProjectName } = useStoreState(
    (state) => state.filters,
  );
  const { t } = useTranslation();
  const { getLayoutAbbr } = useFlatLayout();

  const { flats: data, addedKeys } = exportData;

  const currentPriceKey = withVAT
    ? 'current_price_with_VAT'
    : 'current_price_without_VAT';

  const translateKey = (key: string): string => {
    const translationMapping: Record<string, string> = {
      id: 'ID',
      layout: t('enums.house_parts.layout', 'Layout'),
      floor_area: t('enums.house_parts.floor_area', 'Floor area'),
      exterior_area: t(
        'market.reports.size_comparison.switch.exterior_area',
        'Exterior area',
      ),
      floor: t('price_update.title.floor', 'Floor'),
      orientation: t('enums.house_areas.orientation', 'Orientation'),
      availability: t('market.export.columns.availability', 'Availability'),
      price_difference: `${t(
        'price_list.table_column_headers.price_difference',
        'Price difference',
      )} (%)`,
      current_price_with_VAT: t(
        'export.current_price',
        'Current price in {{currency}} ({{VAT}})',
        {
          currency: getCurrency(),
          VAT: t('menu.settings.popover.vat_in_prices.with_vat', 'with VAT'),
        },
      ),
      current_price_without_VAT: t(
        'export.current_price_without_vat',
        'Current price in {{currency}} ({{VAT}})',
        {
          currency: getCurrency(),
          VAT: t(
            'menu.settings.popover.vat_in_prices.without_vat',
            'without VAT',
          ),
        },
      ),
      recommended_price: t(
        'detail_preview_card.recommended_price_long',
        'Recommended price',
      ),
      ...Object.fromEntries(
        addedKeys.map((aKey) => [
          aKey,
          t(
            `export.${aKey}`,
            `${aKey.replace('update_', '').split('_').join(' ')} update`,
          ),
        ]),
      ),
    };
    return translationMapping[key] || key;
  };

  const allKeys = [
    'id',
    'layout',
    'floor_area',
    'exterior_area',
    'floor',
    'orientation',
    'availability',
    ...(exportPriceUpdate ? addedKeys : []),
    'price_difference',
    currentPriceKey,
    'recommended_price',
  ];

  const mappedData = data.map((item) => {
    const { name, current_price } = item;

    const new_price = applyRounding(Number(current_price));

    const dynamicExportData = allKeys.reduce((acc, key) => {
      if (key === 'id') {
        acc[key] = name;
      } else if (key === currentPriceKey) {
        acc[key] = new_price;
      } else {
        acc[key] = item[key] ?? '';
      }
      return acc;
    }, {} as Record<string, any>);

    return {
      ...dynamicExportData,
    };
  });

  const handleClick = (format: string): void => {
    const fileName = `${new Date()
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('')}_${
      selectedProjectName ? slugifyString(selectedProjectName) : ''
    }_price_list${getVATText()}`;
    if (mappedData) {
      if (format === 'csv') {
        const translatedHeaders = allKeys.map(translateKey);

        const csvData = mappedData.map((row) =>
          allKeys.reduce((acc, key, index) => {
            const translatedKey = translatedHeaders[index];
            acc[translatedKey] = row[key];
            return acc;
          }, {} as Record<string, any>),
        );
        const csvOptions: Options = {
          ...options,
          headers: translatedHeaders,
        };

        const csvExporterWithHeaders = new ExportToCsv(csvOptions);
        csvExporterWithHeaders.options.filename = fileName;

        csvExporterWithHeaders.generateCsv(csvData);
        return;
      }
      if (format === 'xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const translatedHeaders = allKeys.map(translateKey);
        XLSX.utils.sheet_add_aoa(worksheet, [translatedHeaders], {
          origin: 'A1',
        });
        const priceDifferenceColumn = allKeys.indexOf('price_difference') + 1;
        const recommendedPriceColumn = allKeys.indexOf('recommended_price') + 1;
        const currentPriceColumn = allKeys.indexOf(currentPriceKey) + 1;
        const updateColumns = addedKeys
          .filter((key) => key.startsWith('update_'))
          .map((key) => allKeys.indexOf(key) + 1)
          .filter((colIndex) => colIndex > 0);

        data.forEach((_rowData, rowIndex) => {
          const row = rowIndex + 2;

          if (exportPriceUpdate) {
            // Formula for `price_difference`
            if (updateColumns.length > 0) {
              const firstCell = XLSX.utils.encode_cell({
                r: row - 1,
                c: updateColumns[0] - 1,
              });
              const lastCell = XLSX.utils.encode_cell({
                r: row - 1,
                c: updateColumns[updateColumns.length - 1] - 1,
              });
              const formula = `SUM(${firstCell}:${lastCell})`;
              worksheet[
                XLSX.utils.encode_cell({
                  r: row - 1,
                  c: priceDifferenceColumn - 1,
                })
              ] = {
                f: formula,
              };
            } else {
              worksheet[
                XLSX.utils.encode_cell({
                  r: row - 1,
                  c: priceDifferenceColumn - 1,
                })
              ] = {
                v: 0,
              };
            }
          }

          // Formula for `recommended_price`
          const currentPriceCell = XLSX.utils.encode_cell({
            r: row - 1,
            c: currentPriceColumn - 1,
          });
          const priceDifferenceCell = XLSX.utils.encode_cell({
            r: row - 1,
            c: priceDifferenceColumn - 1,
          });
          const recommendedPriceFormula = `${currentPriceCell} * (${priceDifferenceCell} + 1)`;

          worksheet[
            XLSX.utils.encode_cell({
              r: row - 1,
              c: recommendedPriceColumn - 1,
            })
          ] = {
            f: recommendedPriceFormula,
          };
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
        return;
      }
      const CRMData = data?.map((d) => ({
        internalId: d.name,
        buildingNo: d.building_name,
        floorNo: d.floor,
        price: d.recommended_price ? applyRounding(d.recommended_price) : '',
      }));
      if (CRMData) {
        csvExporter.options.filename = `${fileName}-CRM`;
        csvExporter.generateCsv(CRMData);
      }
    }
  };

  return <ExportDropdown isDisabled={isLoading} type='priceUpdate' onDownload={handleClick} />;
};
