import { useCallback, useEffect } from 'react';
import { useStoreActions, useStoreState } from '.';
import { SimplifiedProjects } from '../store/market';
import { Mode } from '../store/types';
import { LatLng } from '../utils/types';

const mapIncludedProjects = (
  projects: SimplifiedProjects[],
  updatedProjects: SimplifiedProjects[],
): SimplifiedProjects[] =>
  projects.map((project) => ({
    ...project,
    include: !!updatedProjects.find(
      ({ project_id }) => project_id === project.project_id,
    )?.include,
  }));

type UseMap = (
  projects: SimplifiedProjects[],
  filteredProjects: SimplifiedProjects[],
  googleMap: google.maps.Map | undefined,
) => {
  handleCreatePolygon: (polygon: google.maps.Polygon, index: number) => void;
  handleEditPolygon: (polygon: google.maps.Polygon, name: string) => void;
  handleDeletePolygon: (name: string) => void;
  handleCreateCircle: (circle: google.maps.Circle, index: number) => void;
  handleEditCircle: (circle: google.maps.Circle, name: string) => void;
  handleDeleteCircle: (name: string) => void;
  recalculateInclusion: () => void;
};

export const useMap: UseMap = (projects, filteredProjects, googleMap) => {
  const { polygons, circles, mode } = useStoreState((state) => state.market);
  const {
    setPolygons,
    setCircles,
    setSimplifiedProjects,
    resetCircles,
    resetPolygons,
    setSelectedProjectId,
  } = useStoreActions((actions) => actions.market);

  useEffect(
    () => () => {
      resetCircles();
      resetPolygons();
      setSelectedProjectId(null);
    },
    [],
  );

  const resetInclusionForPolygon = (polygonPaths: LatLng[]): void => {
    const polygon = new google.maps.Polygon({ paths: polygonPaths });

    const updatedProjects = filteredProjects.map((project) => {
      const projectLatLng = new google.maps.LatLng(
        project.gps_latitude,
        project.gps_longitude,
      );

      if (google.maps.geometry.poly.containsLocation(projectLatLng, polygon)) {
        return { ...project, include: false };
      }

      return project;
    });

    // Set the updated project list
    const mappedIncudedProjects: SimplifiedProjects[] = mapIncludedProjects(
      projects,
      updatedProjects,
    );
    setSimplifiedProjects(mappedIncudedProjects);
  };

  const resetInclusionForCircle = (
    circleCenter: google.maps.LatLng,
    circleRadius: number,
  ): void => {
    const updatedProjects = filteredProjects.map((project) => {
      const projectLatLng = new google.maps.LatLng(
        project.gps_latitude,
        project.gps_longitude,
      );

      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        circleCenter,
        projectLatLng,
      );

      if (distance <= circleRadius) {
        return { ...project, include: false };
      }

      return project;
    });

    // Set the updated project list
    const mappedIncudedProjects: SimplifiedProjects[] = mapIncludedProjects(
      projects,
      updatedProjects,
    );
    setSimplifiedProjects(mappedIncudedProjects);
  };

  const recalculateInclusion = useCallback(() => {
    if (mode === Mode.READ) return;

    const updatedProjects = filteredProjects.map((project) => {
      const projectLatLng = new google.maps.LatLng(
        project.gps_latitude,
        project.gps_longitude,
      );

      // Check if the project is included in any circle
      const isIncludedInAnyCircle = Object.values(circles).some((circle) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          circle.circleCenter,
          projectLatLng,
        );
        return distance <= circle.circleRadius;
      });

      // Check if the project is included in any polygon
      const isIncludedInAnyPolygon = Object.values(polygons).some(
        (polygonPaths) => {
          const polygon = new google.maps.Polygon({ paths: polygonPaths });
          return google.maps.geometry.poly.containsLocation(
            projectLatLng,
            polygon,
          );
        },
      );

      return {
        ...project,
        include:
          isIncludedInAnyCircle || isIncludedInAnyPolygon
            ? true
            : project.include,
      };
    });

    const mappedIncudedProjects: SimplifiedProjects[] = mapIncludedProjects(
      projects,
      updatedProjects,
    );
    setSimplifiedProjects(mappedIncudedProjects);
  }, [projects, circles, polygons, setSimplifiedProjects]);

  const handleEditPolygon = (
    polygon: google.maps.Polygon,
    name: string,
  ): void => {
    const polygonPaths = polygon.getPath().getArray();

    // Reset inclusion for all projects inside the old polygon before editing
    if (polygons[name]) {
      resetInclusionForPolygon(polygons[name]);
    }

    // Update the polygon with new paths
    const newPolygons = {
      ...polygons,
      [name]: polygonPaths,
    };

    setPolygons(JSON.parse(JSON.stringify(newPolygons)));
  };

  const handleEditCircle = (
    updatedCircle: google.maps.Circle,
    name: string,
  ): void => {
    const circleCenter = updatedCircle.getCenter() as google.maps.LatLng;
    const circleRadius = updatedCircle.getRadius();

    // Reset inclusion for all projects inside the old circle before editing
    if (circles[name]) {
      resetInclusionForCircle(
        circles[name].circleCenter,
        circles[name].circleRadius,
      );
    }

    // Update the circle with new center and radius
    const newCircles = {
      ...circles,
      [name]: { circleCenter, circleRadius },
    };

    setCircles(newCircles);
  };

  const handleCreatePolygon = (
    polygon: google.maps.Polygon,
    index: number,
  ): void => {
    const polygonName = `Custom Polygon ${index + 1}`;
    const polygonPaths = polygon.getPath().getArray();
    const newPolygons = {
      ...polygons,
      [polygonName]: polygonPaths,
    };

    const createdPolygon = new google.maps.Polygon({
      paths: polygonPaths,
    });

    const updatedProjects = filteredProjects.map((project) => {
      const projectLatLng = new google.maps.LatLng(
        project.gps_latitude,
        project.gps_longitude,
      );

      if (
        google.maps.geometry.poly.containsLocation(
          projectLatLng,
          createdPolygon,
        )
      ) {
        return { ...project, include: true };
      }
      return { ...project };
    });

    setPolygons(JSON.parse(JSON.stringify(newPolygons)));
    const mappedIncudedProjects: SimplifiedProjects[] = mapIncludedProjects(
      projects,
      updatedProjects,
    );
    setSimplifiedProjects(mappedIncudedProjects);
  };

  const handleCreateCircle = (
    circle: google.maps.Circle,
    index: number,
  ): void => {
    const circleCenter = circle.getCenter();
    const circleRadius = circle.getRadius();

    if (!circleCenter) return;

    const circleName = `Custom Circle ${index + 1}`;
    const newCircle = {
      ...circles,
      [circleName]: { circleCenter, circleRadius },
    };

    setCircles(newCircle);

    const updatedProjects = filteredProjects.map((project) => {
      const projectLatLng = new google.maps.LatLng(
        project.gps_latitude,
        project.gps_longitude,
      );

      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        circleCenter,
        projectLatLng,
      );

      if (distance <= circleRadius) {
        return { ...project, include: true };
      }
      return { ...project };
    });

    const mappedIncudedProjects: SimplifiedProjects[] = mapIncludedProjects(
      projects,
      updatedProjects,
    );
    setSimplifiedProjects(mappedIncudedProjects);
  };

  const handleDeletePolygon = (name: string): void => {
    const polygonPaths = polygons[name];

    if (polygonPaths) {
      // Reset inclusion for all projects inside the polygon before deleting
      resetInclusionForPolygon(polygonPaths);
    }

    // Remove the polygon from the state
    const { [name]: _, ...remainingPolygons } = polygons;
    setPolygons(remainingPolygons);
  };

  const handleDeleteCircle = (name: string): void => {
    const circleData = circles[name];

    if (circleData) {
      const { circleCenter, circleRadius } = circleData;

      // Reset inclusion for all projects inside the circle before deleting
      resetInclusionForCircle(circleCenter, circleRadius);
    }

    // Remove the circle from the state
    const { [name]: _, ...remainingCircles } = circles;
    setCircles(remainingCircles);
  };

  useEffect(() => {
    if (googleMap) {
      recalculateInclusion();
    }
  }, [circles, polygons, googleMap]);

  return {
    handleCreatePolygon,
    handleEditPolygon,
    handleDeletePolygon,
    handleCreateCircle,
    handleEditCircle,
    handleDeleteCircle,
    recalculateInclusion,
  };
};
