/* eslint-disable react/jsx-curly-newline */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  DrawingManager,
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SimplifiedProjects } from '../../store/market';
import { GOOGLE_MAPS_LIBRARIES } from '../../constants';
import { CenteredSpinner } from '../CenteredSpinner';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { MapControls } from '../MapControls/MapControls';
import { ExtendedProjectTable } from './ExtendedProjectsTable';
import { getMapPin } from './getPins';
import { CustomPolygon } from '../map/CustomPolygon';
import { CustomCircle } from './CustomCircle';
import { useMap } from '../../hooks/useMap';
import { MarketProjectExtended, Mode } from '../../store/types';
import { generateGeoJSONCircle, getGeometry } from '../../utils/utils';
import { getProject } from '../../api';
import { PricePerSmCalcType } from '../TopBar/SettingsPopover';
import { ProjectDetail } from '../map/ProjectDetail';
import { formatBreaks } from '../../utils/formatBreaks';
import { MapType, TAGS_MOCK } from '../MapControls/MapProjectStatusTags';
import { ProjectPhase } from '../../api/types';

const calculatePolygonIndex = (geometry: string[]): number => {
  const indices: number[] = geometry
    .map((name) => {
      const parts = name.split(' ');
      const lastPart = parts[parts.length - 1];
      const index = Number(lastPart);
      return Number.isNaN(index) ? null : index;
    })
    .filter((index): index is number => index !== null);

  const maxIndex = indices.length > 0 ? Math.max(...indices) : 0;

  return maxIndex;
};

type Props = {
  projects: SimplifiedProjects[];
  type: MapType;
};

export const Map: FC<Props> = ({ projects, type }) => {
  const locationUrl = useLocation();
  const [mouseOverProjectId, setMouseOverProjectId] = useState<number>();
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [googleMaps, setGoogleMaps] = useState<google.maps.Map>();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedProject, setProjectDetail] =
    useState<MarketProjectExtended | null>(null);
  const [sameLocationProjects, setSameLoactionProjects] = useState<
    MarketProjectExtended[]
  >([]);
  const [shownProject, setShownProject] = useState<
    MarketProjectExtended | undefined
  >();
  const [hoveredGeometry, setHoveredGeometry] = useState<string | null>(null);
  const drawingRef = useRef<DrawingManager | null>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isFullscreenToggle =
    locationUrl.pathname === '/market/reports' ? isFullscreen : true;

  const { t } = useTranslation();
  const { calculateVatPrice } = useRegionFormatting();

  const {
    polygons,
    circles,
    projectsLoading,
    mode,
    primaryParameters,
    selectedProjectId,
    activeTags,
    isMapLoading,
  } = useStoreState((state) => state.market);

  const { profile } = useStoreState((store) => store.user);
  const {
    setSelectedProjectId,
    setPrimaryParameters,
    handleInclude,
    setAvailableMapTags,
    setActiveTags,
  } = useStoreActions((actions) => actions.market);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { overviewData } = useStoreState((state) => state.filters);
  const hoveredProject = useMemo(
    () => projects.find((p) => p.project_id === mouseOverProjectId),
    [mouseOverProjectId, projects],
  );

  const filteredProjectsByPhases = useMemo(
    () =>
      projects.filter(({ project_phase }) =>
        activeTags.includes(project_phase),
      ),
    [projects, activeTags],
  );

  useEffect(() => {
    if (projects.length === 0) return;
    const uniquePhases = projects
      .map((project) => project.project_phase)
      .filter((phase, index, self) => phase && self.indexOf(phase) === index);
    setAvailableMapTags(uniquePhases);

    // Set all tags active except archived when in create report mode
    if (type === MapType.CREATE_REPOROT) {
      const activePhases = uniquePhases.filter(
        (phase) => phase !== ProjectPhase.ARCHIVED,
      );
      setActiveTags(activePhases);
    } else {
      setActiveTags(uniquePhases);
    }
  }, [!!projects.length, type]);

  const locations = useMemo(
    () =>
      overviewData?.cities_with_location.map((cityWithLocation) => ({
        gps_latitude: cityWithLocation.gps_lat,
        gps_longitude: cityWithLocation.gps_long,
      })),
    [overviewData],
  );

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const {
    handleCreatePolygon,
    handleEditPolygon,
    handleDeletePolygon,
    handleCreateCircle,
    handleEditCircle,
    handleDeleteCircle,
    recalculateInclusion,
  } = useMap(projects, filteredProjectsByPhases, googleMaps);

  const needsRecalculationRef = useRef<boolean>(false);

  useEffect(() => {
    if (type === MapType.CREATE_REPOROT) {
      needsRecalculationRef.current = true;
    }
  }, [activeTags]);

  useEffect(() => {
    if (needsRecalculationRef.current) {
      recalculateInclusion();
      needsRecalculationRef.current = false;
    }
  }, [projects]);

  useEffect(() => {
    if (needsRecalculationRef.current) {
      recalculateInclusion();
    }
  }, [filteredProjectsByPhases]);

  useEffect(() => {
    if (
      ((mode === Mode.CREATE || mode === Mode.EDIT) &&
        Object.values(polygons).length > 0) ||
      (Object.values(circles).length > 0 && googleMaps)
    ) {
      const newPolygons = Object.values(polygons).map((p) => [
        ...p.map((point) => new google.maps.LatLng(point)),
      ]);

      const newCircles = Object.values(circles).map((circle) =>
        generateGeoJSONCircle(circle.circleCenter, circle.circleRadius, 100),
      );

      const geometry = getGeometry([...newPolygons, ...newCircles]);
      if (primaryParameters) {
        const newParams = {
          ...primaryParameters,
          geometry,
        };
        setPrimaryParameters(JSON.parse(JSON.stringify(newParams)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons, circles, mode, googleMaps]);

  const fetchData = async (
    projectId: number | null,
  ): Promise<MarketProjectExtended | null> => {
    if (projectId && !Number.isNaN(projectId)) {
      try {
        const fetchedData = await getProject(
          projectId,
          pricePerSmCalculation,
          profile?.VAT_included,
          profile?.reserved_as_sold,
        );
        return fetchedData.data as MarketProjectExtended;
      } catch (error) {
        console.error(`Error fetching project with id ${projectId}:`, error);
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    setProjectDetail(null);
    setSameLoactionProjects([]);
    const projectToFetch = filteredProjectsByPhases.find(
      (project) => project.project_id === selectedProjectId,
    );
    if (!projectToFetch) return;

    const filteredProjects = filteredProjectsByPhases.filter(
      (p) =>
        p.gps_latitude === projectToFetch.gps_latitude &&
        p.gps_longitude === projectToFetch.gps_longitude,
    );

    if (filteredProjects.length > 1) {
      Promise.all(
        filteredProjects.map((project) => fetchData(project.project_id)),
      )
        .then((projectDataArray) => {
          const validProjectDataArray = projectDataArray.filter(
            (projectData) => projectData !== null,
          );
          setSameLoactionProjects(
            validProjectDataArray as MarketProjectExtended[],
          );
        })
        .catch((error) => {
          console.error('Error fetching multiple projects:', error);
        });
    }
    if (selectedProjectId) {
      fetchData(selectedProjectId).then((projectData) => {
        if (projectData) {
          setProjectDetail(projectData);
        }
      });
    }
  }, [selectedProjectId, pricePerSmCalculation]);

  useEffect(() => {
    if (selectedProject) {
      const isIncluded =
        filteredProjectsByPhases.find(
          (project) => project.project_id === selectedProject.project_id,
        )?.include ?? false;
      setShownProject({ ...selectedProject, include: isIncluded });
      setOpen(true);
    }
  }, [calculateVatPrice, selectedProject, profile]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const recenterMap = (googleMap: google.maps.Map): void => {
    const bounds = new window.google.maps.LatLngBounds();

    // Extend bounds with the project locations
    filteredProjectsByPhases.forEach((project) => {
      bounds.extend(
        new window.google.maps.LatLng(
          project.gps_latitude,
          project.gps_longitude,
        ),
      );
    });

    // Extend bounds with the polygon points if polygons are defined
    if (polygons && Object.values(polygons).length > 0) {
      Object.values(polygons).forEach((polygon) => {
        polygon.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
      });
    }

    // Extend bounds with the circles if circles are defined
    if (circles && Object.values(circles).length > 0) {
      Object.values(circles).forEach((circle) => {
        const { circleCenter, circleRadius } = circle;

        // Extend the bounds with the circle's center
        bounds.extend(circleCenter);

        // Calculate the bounds for the circle's radius
        const boundsForCircle = new google.maps.Circle({
          center: circleCenter,
          radius: circleRadius,
        }).getBounds();

        if (boundsForCircle) {
          bounds.union(boundsForCircle);
        }
      });
    }

    // Fit the map to the calculated bounds
    googleMap.fitBounds(bounds);
  };

  const handleDefaultRecenter = (googleMap: google.maps.Map): void => {
    const bounds = new window.google.maps.LatLngBounds();
    locations?.forEach((location) => {
      bounds.extend(
        new window.google.maps.LatLng(
          location.gps_latitude,
          location.gps_longitude,
        ),
      );
    });
    googleMap.fitBounds(bounds);
  };

  const handlePolygonCenter = (mapParam: google.maps.Map): void => {
    const bounds = new google.maps.LatLngBounds();

    // Iterate over polygons and extend the bounds with each polygon's LatLng points
    Object.values(polygons).forEach((polygon) => {
      polygon.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });
    });

    // Iterate over circles and extend the bounds with each circle's center and radius
    Object.values(circles).forEach((circle) => {
      const { circleCenter, circleRadius } = circle;

      // Extend the bounds with the circle's center
      bounds.extend(circleCenter);

      // Calculate the bounds for the circle's radius
      const boundsForCircle = new google.maps.Circle({
        center: circleCenter,
        radius: circleRadius,
      }).getBounds();

      if (boundsForCircle) {
        bounds.union(boundsForCircle);
      }
    });

    // Fit the map to the new bounds
    mapParam.fitBounds(bounds);
  };

  const handleRecenter = (mapParam: google.maps.Map): void => {
    if (filteredProjectsByPhases.length > 0) {
      recenterMap(mapParam);
      return;
    }

    if (
      Object.values(circles).length > 0 ||
      Object.values(polygons).length > 0
    ) {
      handlePolygonCenter(mapParam);
      return;
    }

    if (locations) {
      handleDefaultRecenter(mapParam);
    } else {
      mapParam.setCenter({ lat: 48.1323412, lng: 17.1119535 });
    }
  };

  const onLoad = (mapParam: google.maps.Map): void => {
    setGoogleMaps(mapParam);
    handleRecenter(mapParam);
  };

  const handleClick = (
    projectId: number,
    coordinates?: { lat: number; lng: number },
  ): void => {
    if (clickTimeoutRef.current) return;

    clickTimeoutRef.current = setTimeout(() => {
      if (googleMaps && coordinates) {
        googleMaps.panTo(coordinates);
      }
      setSelectedProjectId(projectId);
      clickTimeoutRef.current = null;
    }, 300);
  };

  const handleDoubleClick = (projectId: number): void => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
    }
    if (mode === Mode.CREATE || mode === Mode.EDIT) {
      handleInclude([projectId]);
    }
  };

  return !isMapLoading && !projectsLoading && isLoaded ? (
    <GoogleMap
      zoom={8}
      options={{
        disableDefaultUI: false,
        fullscreenControl: false,
        clickableIcons: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
      }}
      mapContainerClassName='w-full min-h-[300px] h-full relative project_map'
      onLoad={onLoad}
      onClick={() => {
        setSelectedProjectId(null);
        if (clickTimeoutRef.current) {
          clearTimeout(clickTimeoutRef.current);
          clickTimeoutRef.current = null;
        }
      }}
    >
      {filteredProjectsByPhases.map((project) => {
        const {
          project_id,
          gps_latitude,
          gps_longitude,
          include,
          project_name,
          project_phase,
        } = project;

        const onMouseOver = (): void => setMouseOverProjectId(project_id);
        const onMouseOut = (): void => setMouseOverProjectId(undefined);

        return (
          <Marker
            key={project_id}
            position={{ lat: gps_latitude, lng: gps_longitude }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={() =>
              handleClick(project_id, { lat: gps_latitude, lng: gps_longitude })
            }
            onDblClick={() => handleDoubleClick(project_id)}
            icon={getMapPin(project_phase, !!include)}
          >
            {shownProject &&
            selectedProject?.project_id === project_id &&
            open &&
            isFullscreenToggle ? (
              <InfoWindow
                position={{
                  lat: selectedProject.gps_latitude,
                  lng: selectedProject.gps_longitude,
                }}
                options={{
                  minWidth: 600,
                }}
              >
                <ProjectDetail
                  selectedProject={shownProject}
                  selectedProjectId={selectedProjectId}
                  sameLocationProjects={sameLocationProjects}
                  onClose={() => {
                    setOpen(false);
                    setSelectedProjectId(null);
                  }}
                />
              </InfoWindow>
            ) : (
              hoveredProject?.project_id === project_id && (
                <InfoWindow
                  position={{
                    lat: hoveredProject.gps_latitude,
                    lng: hoveredProject.gps_longitude,
                  }}
                >
                  <div className='flex flex-col'>
                    <span className='font-bold text-bmblue'>
                      {project_name}
                    </span>
                    {mode === Mode.CREATE || mode === Mode.EDIT ? (
                      <>
                        <span>
                          {formatBreaks(
                            t('market.reports.click'),
                            '***',
                            false,
                            1,
                            700,
                          )}
                        </span>
                        <span>
                          {formatBreaks(
                            t('market.reports.double_clicks'),
                            '***',
                            false,
                            1,
                            700,
                          )}
                        </span>
                      </>
                    ) : null}
                  </div>
                </InfoWindow>
              )
            )}
          </Marker>
        );
      })}
      <DrawingManager
        ref={drawingRef}
        options={{
          drawingControl: false,
          polygonOptions: {
            fillColor: '#5A72B1',
            fillOpacity: 0.5,
            strokeColor: '#5A72B1',
            editable: true,
          },
          circleOptions: {
            fillColor: '#5A72B1',
            fillOpacity: 0.5,
            strokeColor: '#5A72B1',
            editable: true,
          },
        }}
        onPolygonComplete={
          (polygon) =>
            handleCreatePolygon(
              polygon,
              calculatePolygonIndex(Object.keys(polygons)),
            ) /* eslint-disable react/jsx-curly-newline */
        }
        onCircleComplete={
          (circle) =>
            handleCreateCircle(
              circle,
              calculatePolygonIndex(Object.keys(circles)),
            ) /* eslint-disable react/jsx-curly-newline */
        }
        onOverlayComplete={(e) => {
          e.overlay?.setMap(null);
        }}
      />
      {googleMaps && (
        <MapControls
          mapParam={googleMaps}
          onChangeFullScreen={setIsFullscreen}
          drawingManagerRef={drawingRef}
          handleModalOpen={() => setModalOpen((prev) => !prev)}
          openModal={modalOpen}
          setIsHovered={setHoveredGeometry}
          handleRecenter={handleRecenter}
          projects={filteredProjectsByPhases}
          mapTypeForTags={type}
        />
      )}
      {modalOpen && (
        <ExtendedProjectTable
          open={modalOpen}
          handleModalClose={() => setModalOpen(false)}
        />
      )}
      {Object.values(polygons).length > 0 &&
        Object.entries(polygons).map(([name, polygon]) => (
          <CustomPolygon
            key={name}
            name={name}
            paths={polygon}
            isHoveredGeometry={hoveredGeometry === name}
            onUpdate={handleEditPolygon}
            onDelete={handleDeletePolygon}
          />
        ))}
      {Object.values(circles).length > 0 &&
        Object.entries(circles).map(([name, circle]) => (
          <CustomCircle
            key={name}
            name={name}
            isHoveredGeometry={hoveredGeometry === name}
            circleCenter={circle.circleCenter}
            circleRadius={circle.circleRadius}
            onUpdate={handleEditCircle}
            onDelete={handleDeleteCircle}
          />
        ))}
    </GoogleMap>
  ) : (
    <CenteredSpinner />
  );
};
