import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LayoutsMetric, ProjectsMetric, SupplyHistoryMonthData } from '../../../../../api/types';
import { useFlatLayout, useRegionFormatting } from '../../../../../hooks';
import styles from './SupplyHistoryModal.module.css';
import { getAvg, getSumFromNotNullValues } from '../../../../../utils/utils';

type Props = {
  tableData: SupplyHistoryMonthData;
  filterValue: 'projects' | 'layouts';
  loading: boolean;
}

export const SupplyHistoryModalTable: FC<Props> = ({ tableData, filterValue, loading }) => {
  const { t } = useTranslation();
  const rowKey = filterValue.slice(0, -1);
  const { formatCurrency, formatAreaUnits, areaUnit } = useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const projectsColumns: TableProps<ProjectsMetric>['columns'] = useMemo(() => [
    {
      title: (
        <div className='flex items-center justify-start space-x-2'>
          <span>
            {t('market.projects.table.project_name', 'Project')}
          </span>
        </div>
      ),
      dataIndex: 'project',
      render: (value) => {
        const projectId = tableData.projects_metrics.find((pm) => pm.project === value)?.project_id;
        return (
        <Link to={`/project/${projectId}`} target='_blank'>
          <span>{value}</span>
        </Link>
        );
    },
      align: 'left',
    },
    // {
    //   title: (
    //     <div className='flex items-center justify-start space-x-2'>
    //       <span>
    //         {t('market.projects.table.developer', 'Developer')}
    //       </span>
    //     </div>
    //   ),
    //   dataIndex: 'developer',
    //   render: (value) => value,
    //   align: 'left',
    // },
    // {
    //   title: (
    //     <div className='flex items-center justify-center space-x-2'>
    //       <span>
    //         {t('market.supply.available_units', 'Available units')}
    //       </span>
    //     </div>
    //   ),
    //   dataIndex: 'available_units',
    //   render: (value) => value,
    //   align: 'center',
    // },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.units', 'Units')}
          </span>
        </div>
      ),
      dataIndex: 'total_units',
      render: (value) => value,
      align: 'center',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.price_per_unit', 'AVG Price per {{unit}}', {
              unit: areaUnit,
            })}
          </span>
        </div>
      ),
      dataIndex: 'average_price_per_sm',
      render: (value) => formatCurrency(value),
      align: 'center',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.price', 'Price')}
          </span>
        </div>
      ),
      dataIndex: 'average_price',
      render: (value) => formatCurrency(value),
      align: 'center',
    },
  ], [areaUnit, formatCurrency, t]);

  const layoutColumns: TableProps<LayoutsMetric>['columns'] = useMemo(() => [
    {
      title: (
        <div className='flex items-center justify-start space-x-2'>
          <span>
            {t('project.table.layout', 'Layout')}
          </span>
        </div>
      ),
      dataIndex: 'layout',
      render: (value) => getLayoutAbbr(value || null),
      align: 'left',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.units', 'Units')}
          </span>
        </div>
      ),
      dataIndex: 'total_units',
      render: (value) => value,
      align: 'center',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.interior_unit', 'Interior {{unit}}', {
              unit: areaUnit,
            })}
          </span>
        </div>
      ),
      dataIndex: 'average_interior',
      render: (value) => formatAreaUnits(value),
      align: 'center',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.price_per_unit', 'AVG Price per {{unit}}', {
              unit: areaUnit,
            })}
          </span>
        </div>
      ),
      dataIndex: 'average_price_per_sm',
      render: (value) => formatCurrency(value),
      align: 'center',
    },
    {
      title: (
        <div className='flex items-center justify-center space-x-2'>
          <span>
            {t('project.table.price', 'Price')}
          </span>
        </div>
      ),
      dataIndex: 'average_price',
      render: (value) => formatCurrency(value),
      align: 'center',
    },
  ], [areaUnit, formatAreaUnits, formatCurrency, getLayoutAbbr, t]);

  return (
    filterValue === 'projects' ? (
      <Table
        className={styles.table}
        columns={projectsColumns}
        pagination={
          tableData.projects_metrics.length > 5 ?
          {
            pageSize: 5,
          } : false
        }
        rowKey={rowKey}
        loading={loading}
        dataSource={tableData.projects_metrics}
        summary={(pageData) => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} />
            {/* <Table.Summary.Cell index={1} /> */}
            {/* <Table.Summary.Cell index={2} align='center'>
              <b>{getSumFromNotNullValues(pageData.map((pd) => pd.available_units))}</b>
            </Table.Summary.Cell> */}
            <Table.Summary.Cell index={1} align='center'>
              <b>{getSumFromNotNullValues(pageData.map((pd) => pd.total_units))}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align='center'>
              <b>{formatCurrency(getAvg(pageData.filter((pd) => !!pd.average_price_per_sm).map((pd) => pd.average_price_per_sm)))}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} align='center'>
              <b>{formatCurrency(getAvg(pageData.filter((pd) => !!pd.average_price).map((pd) => pd.average_price)))}</b>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    ) : (
      <Table
        className={styles.table}
        columns={layoutColumns}
        pagination={false}
        rowKey={rowKey}
        loading={loading}
        dataSource={tableData.layouts_metrics}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} />
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} align='center'>
              <b>{formatAreaUnits(tableData.average_interior)}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} align='center'>
              <b>{formatCurrency(tableData.average_price_per_sm)}</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align='center'>
              <b>{formatCurrency(tableData.average_price)}</b>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    )
  );
};
